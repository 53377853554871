<template>
    <div id="sys-comment">
        <el-row>
            <el-col :span="8"><el-button type="danger" @click="handleDelete">删除</el-button></el-col>
            <el-col :span="16">
                <el-radio-group v-model="commentTypeVal" style="margin-bottom: 20px" @change="commentTypeFilter">
                    <el-radio-button v-for="(commentType,index) in commentTypes" :key="index" :label="commentType">{{ commentTypeFmt(commentType) }}</el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-table :data="list" stripe style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" />
            <el-table-column label="时间" width="160">
                <template #default="scope">
                    {{ commentDateFmt(scope.row.createTime) }}
                </template>
            </el-table-column>
            <el-table-column label="联系方式" prop="contactNo" width="180"/>
            <el-table-column label="内容" prop="commentContent" />
            <el-table-column label="有效" width="160">
                <template #default="scope">
                    <el-switch v-model="scope.row.commentState" @change="handleState(scope.row,false)" size="small" :active-value="1" :inactive-value="0" />
                </template>
            </el-table-column>
            <el-table-column label="已读" width="160">
                <template #default="scope">
                    <el-switch v-model="scope.row.readState" @change="handleState(scope.row,true)" size="small" :active-value="1" :inactive-value="0"/>
                </template>
            </el-table-column>
            <el-table-column fixed="right" width="120">
                <template #header>
                    <el-select v-model="readStateVal" size="small" @change="loadData">
                        <el-option label="全部" value=""/>
                        <el-option label="未读" value="0"/>
                        <el-option label="已读" value="1"/>
                    </el-select>
                </template>
                <template #default="scope">
                    <el-button size="small" type="success" @click="handleReply(scope.row)">回复</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next, jumper"
            :total="allRows" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>

        <comp-comment @commentBarEmit="commentBarEmit" :commentProp="commentProp"></comp-comment>
    </div>
</template>
<style>
#sys-comment{height: calc(100% - 200px);}
#sys-comment .el-col{text-align: left;}
</style>
<script>
import CompComment from "../comm/CompComment.vue"
const COMMENT_TYPES = [{key:"sys",value:"系统留言"},{key:"doc",value:"评论"}];
export default {
    name:"CompSysComment",
    data(){
        return{
            multipleSelection:[],readStateVal:"",commentTypeVal:"sys",commentTypes:[],
            commentProp:{title:"回复留言",show:false,readOnly:true,email:""},
            list:[],replyUid:"",
            pageNum:1,pageSize:10,allRows:0
        }
    },
    methods:{
        commentTypeFilter(){
            this.loadData();
        },
        commentTypeFmt(type){
            return COMMENT_TYPES.filter(p=>p.key==type)[0].value;
        },
        commentDateFmt(date){
            return this.$base.fmtDate(date,"yyyyMMdd HH:mm:ss");
        },
        commentBarEmit(val){
            this.commentProp.show = val.show;
            if(val.data){
                let commentReq = {reqPath: '/comment/add',uid:this.replyUid,contactNo:"R"+val.data.email,commentContent:val.data.content,commentType:"sys"};
                this.$baseApi({data:commentReq}).then(res=>{
                    res.data && 1==res.code?
                        this.$message({type:"success",message:"回复留言成功."}):this.$message({type:"error",message:res.message});
                    this.loadData();
                }).catch(()=>{});
            }
        },
        handleSizeChange(){
            console.log("handleSizeChange");
        },
        handleCurrentChange(val){
            this.pageNum = val;
            this.loadData();
        },
        handleState(row,readFlag){
            let req = {reqPath:'/comment/updState',uid:row.uid};
            if(readFlag){
                Object.assign(req,{readState:row.readState});
            }else{
                Object.assign(req,{commentState:row.commentState});
            }
            this.$baseApi({data:req})
            .then(res=>{
                res.code==1 ? this.$message({type:"success",message:"操作成功."}):this.$message({type:"error",message:res.message});
                this.loadData();
            }).catch(()=>{});
        },
        handleDelete(){
            let uids = [];
            this.multipleSelection.forEach(p=>{
                uids.push(p.uid);
            });
            this.$baseApi({data:{reqPath: '/comment/delComments',uids:uids}})
            .then(res=>{
                res.code==1 ? this.$message({type:"success",message:"删除成功."}):this.$message({type:"error",message:res.message});
                this.loadData();
            }).catch(()=>{});
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        handleReply(row){
            this.replyUid = row.uid;
            this.commentProp.show = true;
            this.commentProp.email = row.contactNo;
        },
        loadData(){
            this.$baseApi({data:{reqPath:"/comment/list",readState:this.readStateVal,commentType:this.commentTypeVal,pageNum:this.pageNum,pageSize:this.pageSize}}).then(res=>{
                this.list = res.data.comments;
                this.allRows = res.data.rows;
                this.commentTypes = res.data.types;
            }).catch(()=>{});
        }
    },
    mounted(){
        this.loadData();
    },
    components:{CompComment}
}
</script>
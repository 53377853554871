<template>
    <div id="app-main-book" style="min-height: calc(100% - 300px);" v-loading="loading" element-loading-text="加载中...">
        <el-radio-group v-model="bookTypeVal" style="margin-bottom: 20px">
            <el-radio-button label="01">全部</el-radio-button>
            <el-radio-button label="02">技术</el-radio-button>
            <el-radio-button label="03">其他</el-radio-button>
        </el-radio-group>
        <el-row :gutter="20">
            <el-col :xl="6" :lg="6" :md="12" :sm="12" :xs="24" v-for="index in 4" :key="index">
                <div class="book-type">技术</div>
                <el-card class="main-life-card book-container" shadow="hover">
                    <div class="book-img"><img src="../assets/images/nanjing.jpg"></div>
                    <div class="book-content">
                        <div class="book-title"><b>雪中悍刀行</b><small>烽火戏诸侯</small></div>
                        <div class="book-desc"><small>小舟从此逝，静海机遇是小舟从此逝，静海机遇是小舟从此逝，静海机遇是小舟从此逝，静海机遇是小舟从此逝，静海机遇是小舟从此逝，静海机遇是小舟从此逝，静海机遇是小舟从此逝，静海机遇是小舟从此逝，静海机遇是</small></div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <comp-footer></comp-footer>
    </div>
</template>
<script>
import CompFooter from "@/components/comm/CompFooter.vue"

export default {
    name:"AppMainBook",
    emit:["compEmit"],
    data(){
        return{
            loading:false,bookTypeVal:"01"
        }
    },
    methods:{
        
    },
    components:{CompFooter}
}
</script>
<style>
.book-type{position: relative;width: 20px;height: 14px;top: 10px;color: #FFF;background: orange;padding: 2px 4px;font-size: 10px;border-radius: 3px;}
.book-container{text-align: left;cursor: pointer;}
.book-container .el-card__body{display: flex;padding: 10px;align-items: center;}
.book-container a{text-decoration: none;color: #409EFF;}
.book-container .book-img>img{max-height: 66px;max-width: 66px;display: flex;}
.book-container .book-content{padding: 0 5px;height: 76px;}
.book-container .book-content .book-title{color: #409EFF;}
.book-content .book-title small{font-size: 12px;margin-left: 15px;}
.book-container .book-content .book-desc{color: #757272;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;}

</style>
<template>
    <div><h1>TEST...</h1>
        <el-button type="primary" @click="doSubscribe">订阅</el-button><el-button type="primary" @click="doPublish">发布</el-button>
        <el-button type="primary" @click="getValue">获取环境变量</el-button>
        <p>{{ value }}</p>
        <el-button type="primary" @click="encrypt">aes encrypt</el-button>
        <p>加密后：{{ ciphertext }}</p>
        <el-button type="primary" @click="decrypt">aes decrypt</el-button>
        <p>解密后：{{ originalText }}</p>
        <el-button type="primary" @click="encrypt_md5">MD5 encrypt</el-button>
        <p>MD5加密后：{{ ciphertextMD5 }}</p>
        <el-button type="primary" @click="reqTest">请求测试</el-button>
    </div>
</template>
<style>

</style>
<script>
var CryptoJS = require("crypto-js");
const aaa = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPT_KEY);
const bbb = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPT_IV);
export default {
    name:"CompVueTest",
    data(){
        return{
            value:"",ciphertext:"",originalText:"",ciphertextMD5:"",
            data:{reqPath:"/sys/menus"}
        }
    },
    methods:{
        doPublish(){
            this.$base.publish("/buer/sysMsg",{title:"标题",content:"今天天气真寒冷！"});
        },
        doSubscribe(){
            this.$base.subscribe("/buer/sysMsg",(data)=>{
                console.log("sub data",data);
                this.$message({type:"success",message:data.content});
            })
        },
        reqTest(){
            this.$baseApi({method:"post",data:this.data}).then(res=>{
                console.log("test",res);
            }).catch(()=>{});
        },
        getValue(){
            console.log("env",process.env);
            this.value = process.env.VUE_APP_CRYPT_KEY;
        },
        encrypt(){
            // Encrypt
            let srcs = CryptoJS.enc.Utf8.parse(JSON.stringify(this.data));
            let encrypted = CryptoJS.AES.encrypt(srcs, aaa, {
                iv: bbb ,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            })
            this.ciphertext = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
        },
        decrypt(){
            // Decrypt
            let base64  = CryptoJS.enc.Base64.parse(this.ciphertext);
            let srcs = CryptoJS.enc.Base64.stringify(base64);
            const decrypt = CryptoJS.AES.decrypt(srcs, aaa, {
                iv: bbb ,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            });
            this.originalText = decrypt.toString(CryptoJS.enc.Utf8);
        },
        encrypt_md5(){
            this.ciphertextMD5 = CryptoJS.MD5(JSON.stringify(this.data));
        }
    }
}
</script>
<template>
    <el-drawer class="tool-chat-drawer" v-model="show" direction="rtl" @close="handleClose">
        <template #header><h4>SimpleChat</h4></template>
        <template #default>
            <ul v-show="userListShow" class="chat-user-list" style="overflow: auto">
                <li v-for="(user,index) in users" :key="index" class="chat-user-item" @click="chooseUser(user,index)">
                    <div class="chat-user-thumb"><img class="img-thumb" :src="user.thumb"></div>
                    <div class="chat-user-info">{{ user.name }}</div>
                </li>
            </ul>
            <div v-show="!userListShow">
                <el-container>
                    <el-header class="chat-header">
                        <div class="chat-head"><img @click="goBack" src="../../assets/images/comp/arrowLeft.png"><b>{{ userChoosed.name }}</b></div>
                    </el-header>
                    <el-main class="chat-main">
                        <div>
                            <div class="chat-content-text">你好啊</div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                        </div>
                        <div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                            <div class="chat-content-text">你好啊你好啊你好啊你好啊你好啊你好啊你好啊你好啊你好啊你好啊你好啊</div>
                        </div>
                        <div>
                            <div class="chat-content-text">你好啊</div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                        </div>
                        <div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                            <div class="chat-content-text">你好啊</div>
                        </div>
                        <div>
                            <div class="chat-content-text">你好啊你好啊你好啊你好啊你好啊你好啊你好啊你好啊你好啊你好啊</div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                        </div>
                        <div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                            <div class="chat-content-text">你好啊</div>
                        </div>
                        <div>
                            <div class="chat-content-text">你好啊</div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                        </div>
                        <div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                            <div class="chat-content-text">你好啊</div>
                        </div>
                        <div>
                            <div class="chat-content-text">你好啊</div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                        </div>
                        <div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                            <div class="chat-content-text">你好啊</div>
                        </div>
                        <div>
                            <div class="chat-content-text">你好啊</div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                        </div>
                        <div>
                            <div class="chat-content-img"><img :src="userChoosed.thumb"></div>
                            <div class="chat-content-text">你好啊</div>
                        </div>
                    </el-main>
                    <el-footer class="chat-footer">
                        <comp-input @compInputEmit="compInputEmit" :inputProp="inputProp"></comp-input>
                    </el-footer>
                </el-container>
            </div>
        </template>
    </el-drawer>
</template>
<style>
.tool-chat-drawer img{max-height: 90%;max-width: 90%;}
.tool-chat-drawer .el-drawer__header{margin-bottom: 0;}
.tool-chat-drawer .el-drawer__body{padding-top: 0;}
.chat-user-list{padding: 0;}
.chat-user-item{height: 40px;display: flex;background: #EEE;border-bottom: 1px solid #FFF;}
.chat-user-item:hover{cursor: pointer;background: #dbdada;}
.chat-user-item>div{height: 100%;align-items: center;display: flex;padding: 0 5px;}
.chat-header{padding: 0;height: 40px;}
.chat-head,.chat-content-img{height: 40px;}
.chat-head{display: flex;align-items: center;}
.chat-head img{max-height: 50%;margin-right: 10px;}
.chat-main{width: 100%;overflow-x: hidden;padding-top: 0;padding-bottom: 0;background: #EEE;border-radius: 5px;height: 360px;}
.chat-main>div{display: flex;margin: 10px 0;}
.chat-main>div:nth-child(2n+1){justify-content: end;}
.chat-main .chat-content-text{text-align: left;background: #FFF;color: #000;padding: 5px;margin: 0 2px;border-radius: 5px;max-width: calc(100% - 85px);font-size: 12px;}
.chat-main .chat-content-text:nth-child(2n+1){background: #95EC69;}
.chat-footer{padding: 0;}
</style>
<script>
import CompInput from './CompInput.vue';
export default {
    name:"CompChat",
    props:{
        chatShow:{type:Boolean,default:false}
    },
    emits:['charBarEmit'],
    data(){
        return{
            show:this.chatShow,userChoosed:{},userListShow:true,textInput:"",
            inputProp:{rows:3},
            users:[
                {id:"01",thumb:require("../../assets/images/nanjing.jpg"),name:"张三"},
                {id:"02",thumb:require("../../assets/images/nanjing.jpg"),name:"李四"},
                {id:"03",thumb:require("../../assets/images/nanjing.jpg"),name:"王五"},
                {id:"04",thumb:require("../../assets/images/nanjing.jpg"),name:"赵六"}
            ]
        }
    },
    methods:{
        goBack(){
            this.userListShow = true;
        },
        chooseUser(user,index){
            this.userChoosed = user;
            this.userListShow = false;
            console.log("chooseUser",user,index);
        },
        compInputEmit(obj){
            console.log("chat",obj);
        },
        chatSend(){

        },
        handleClose(){
            this.$emit('charBarEmit',false);
        }
    },
    watch:{
        "chatShow"(val){
            this.show = val;
        }
    },
    components:{CompInput}
}
</script>
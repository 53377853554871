<template>
    <h1>More...</h1>
</template>
<script>
export default {
    name:"AppMainMore",
    data(){
        return{
        }
    },
    methods:{
        
    },
    components:{
    }
}
</script>
<style>

</style>
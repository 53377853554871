<template>
    <div>
        <el-container id="sys-main" :class="[liFontFlag?'appLiFont':'']">
            <el-header><div class="sys-logo"><img @click="fontChange" src="../../assets/images/logo.png"></div><div style="text-align: center;color:#409eff;height: 100%;justify-items: center;display: grid;align-items: center;font-size: 1.6em;"><b>管理页面</b></div></el-header>
            <el-container>
                <el-aside style="width: auto;">
                    <el-menu :collapse="isCollapse" default-active="1">
                        <el-menu-item v-for="(menu,idx) in sysMenus" :index="menuItemIdxFmt(idx)" :key="idx" @click="chooseMenu(menu)">
                        <el-icon><component :is="menu.icon" /></el-icon>
                        <!-- <span>{{menu.name}}</span> -->
                        <template #title>{{menu.name}}</template>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <el-main>
                    <component :is="workcomp" @menuFresh="menuFresh"></component>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<style>
#sys-main>.el-container{height: calc(100% - 100px);}
#sys-main>.el-container>.el-main>div{height: 100%;overflow: hidden;}
#sys-main .el-table{height: calc(100% - 100px);}
.el-drawer{width:80% !important;}
.el-drawer .el-drawer__header{margin-bottom: 0;}
.sys-logo{width: 166px;height: 66px;float: left;}
.sys-logo>img{max-height: 90%;}
</style>
<script>
import CompSysMenu from "@/components/sys/SysMenu.vue"
import CompSysList from "@/components/sys/SysList.vue"
import CompSysListType from "@/components/sys/SysListType.vue"
import CompSysComment from "@/components/sys/SysComment.vue"
import CompSysFactory from "@/components/sys/SysFactory.vue"
import CompSysDom from "@/components/sys/SysDom.vue"
import CompSysGuide from "@/components/sys/SysGuide.vue" 

export default {
    name:"VueSys",
    data(){
        return{
            sysMenus:[],workcomp:"comp-sys-menu",isCollapse:true,liFontFlag:true
        }
    },
    methods:{
        loadMenus(){
            this.$baseApi({data:{reqPath:"/sys/menus"}}).then(res=>{
                this.sysMenus = res.data.menus.filter(p=>p.type=="sys");
            }).catch(()=>{});
        },
        fontChange(){
            this.liFontFlag = !this.liFontFlag;
        },
        chooseMenu(menu){
            if(0==menu.state){
                this.$message({message: "菜单暂时未启用.",type: 'error'});
                return;
            }
			this.workcomp = menu.comp;
		},
		menuItemIdxFmt(idx){
			return ""+(idx+1);
		},
        menuFresh(val){
            if(val) this.loadMenus();
        }
    },
    components:{
        CompSysMenu,CompSysList,CompSysComment,CompSysFactory,CompSysDom,CompSysGuide,CompSysListType
    },
    mounted(){
        this.loadMenus();
    }
}
</script>
<template>
    <div id="sys-menu">
        <el-row>
            <el-col :span="8"><el-button type="success" @click="handleAdd">新增</el-button></el-col>
            <el-col :span="16">
                <el-radio-group v-model="typeVal" style="margin-bottom: 20px" @change="typeChange">
                    <el-radio-button label="sys">系统</el-radio-button>
                    <el-radio-button label="index">主页</el-radio-button>
                    <el-radio-button label="bar">工具</el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-table :data="showMenus" stripe>
            <el-table-column label="菜单名称" prop="name"/>
            <el-table-column label="组件" prop="comp" width="160"/>
            <el-table-column label="状态" width="100">
                <template #default="scope">
                    <el-tag v-if="1==scope.row.state" type="success">启用</el-tag>
                    <el-tag v-else type="danger">停用</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="类型" width="100">
                <template #default="scope">
                    {{ 'sys'==scope.row.type?'系统':'index'==scope.row.type?'主页':"工具" }}
                </template>
            </el-table-column>
            <el-table-column label="父菜单" width="100">
                <template #default="scope">
                    {{ parents[pid=scope.row.parentId].pname }}
                    <!-- <el-select disabled v-model="scope.row.parentId" size="small">
                        <el-option v-for="(pmenu,idx) in parents" :key="idx" :label="pmenu.pname" :value="pmenu.pid"/>
                    </el-select> -->
                </template>
            </el-table-column>
            <el-table-column label="排序" width="80">
                <template #default="scope">
                    <span v-if="scope.row.comp!=adminComp && scope.$index==1"><el-icon color="#B3B3B3"><Top/></el-icon><el-icon @click="onBottom(scope.row)" color="#409EFF"><Bottom/></el-icon></span>
                    <span v-else-if="scope.row.comp!=adminComp && scope.$index+1==menus.length"><el-icon @click="onTop(scope.row)" color="orange"><Top/></el-icon><el-icon color="#B3B3B3"><Bottom/></el-icon></span>
                    <span v-else-if="scope.row.comp!=adminComp && scope.$index+1<menus.length && scope.$index>0"><el-icon @click="onTop(scope.row)" color="orange"><Top/></el-icon><el-icon @click="onBottom(scope.row)" color="#409EFF"><Bottom/></el-icon></span>
                    </template>
                </el-table-column>
            <el-table-column label="图标" prop="icon" width="140"/>
            <el-table-column fixed="right" width="140">
                <template #default="scope">
                    <el-button :disabled="scope.row.comp==adminComp" size="small" type="warning" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button :disabled="scope.row.comp==adminComp" size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-drawer v-model="drawerShow" direction="rtl">
            <template #header><h4>菜单{{ addFlag?'新增':'编辑' }}</h4></template>
            <template #default>
                <el-form ref="form" :model="formData" label-width="80px">
                    <el-form-item v-if="!addFlag" label="序号">
                        <el-input readonly v-model="formData.id"></el-input>
                    </el-form-item>
                    <el-form-item label="名称">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item label="组件">
                        <el-input :disabled="!addFlag" v-model="formData.comp"></el-input>
                    </el-form-item>
                    <el-form-item label="图标">
                        <el-input v-model="formData.icon"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="formData.state">
                            <el-option label="启用" :value="1"/>
                            <el-option label="停用" :value="0"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-select v-model="formData.type">
                            <el-option label="系统" value="sys"/>
                            <el-option label="主页" value="index"/>
                            <el-option label="工具" value="bar"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="父菜单">
                        <el-select v-model="formData.parentId">
                            <el-option v-for="(pmenu,idx) in parents" :key="idx" :label="pmenu.pname" :value="pmenu.pid"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="confirmClick">确定</el-button>
                    </el-form-item>
                </el-form>
            </template>
        </el-drawer>
    </div>
</template>
<style>
#sys-menu .el-col{text-align: left;}
</style>
<script>
export default {
    name:"CompSysMenu",
    emits:['menuFresh'],
    data(){
        return{
            parents:[],drawerShow:false,addFlag:true,
            adminComp:"comp-sys-menu",typeVal:"sys",
            formData:{},menus:[],showMenus:[]
        }
    },
    methods:{
        typeChange(){
            this.showMenus = this.menus.filter(p=>p.type==this.typeVal);
        },
        onTop(row){
            console.log(row);
        },
        onBottom(row){
            console.log(row);
        },
        handleAdd(){
            this.drawerShow = true;
            this.addFlag = true;
            this.formData = {state:1,type:"sys",parentId:0};
        },
        handleEdit(row){
            this.drawerShow = true;
            this.addFlag = false;
            Object.assign(this.formData,row);
        },
        handleDelete(row){
            this.$baseApi({data:Object.assign({reqPath:'/sys/delMenu'},row)}).then(()=>{
                this.drawerShow = false;
                this.loadMenus();
                this.$emit("menuFresh",true);
            })
        },
        confirmClick(){
            this.$baseApi({data:Object.assign({reqPath:this.addFlag?'/sys/addMenu':'/sys/updMenu'},this.formData)}).then(()=>{
                this.drawerShow = false;
                this.loadMenus();
                this.$emit("menuFresh",true);
            })
        },
        loadMenus(){
            this.$baseApi({data:{reqPath:"/sys/menus"}}).then(res=>{
                this.menus = res.data.menus;
                this.showMenus = this.menus.filter(p=>p.type==this.typeVal);
                this.parents.push({pid:0,pname:"无"});
                this.showMenus.forEach(m=>{
                    this.parents.push({pid:m.id,pname:m.name});
                });
            });
        }
    },
    mounted(){
        this.loadMenus();
    }
}
</script>
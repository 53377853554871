<template>
    <el-drawer v-model="drawerShow" direction="rtl" @close="handleClose">
        <template #header><h4>{{ commentTitle() }}</h4></template>
        <template #default>
            <el-form ref="form" :model="formData" label-width="80px">
                <el-form-item label="邮箱">
                    <el-input :readonly="commentProp.readOnly" v-model="formData.email"></el-input>
                </el-form-item>
                <el-form-item label="留言">
                    <comp-input @compInputEmit="compInputEmit" :inputProp="inputProp"></comp-input>
                </el-form-item>
            </el-form>
        </template>
    </el-drawer>
</template>
<style>

</style>
<script>
import CompInput from './CompInput.vue';
export default {
    name:"CompComment",
    props:{
        commentProp:{type:Object ,default:null}
    },
    emits:['commentBarEmit'],
    data(){
        return{
            drawerShow:this.commentProp.show,inputProp:{name:"发送留言",rows:3},
            formData:{email:this.commentProp.email||"",content:null}
        }
    },
    methods:{
        commentTitle(){
            return this.commentProp && this.commentProp.title?this.commentProp.title:"留言";
        },
        commentSubmit(){
            console.log("commentSubmit");
        },
        handleClose(){
            this.$emit("commentBarEmit",{show:false});
        },
        compInputEmit(obj){
            if(!(this.formData.email && this.formData.email.trim())){
                this.$message({type:"error",message:"邮箱不能为空."});
                return;
            }
            if(!this.formData.email.endsWith(".com")||this.formData.email.indexOf("@")<0){
                this.$message({type:"error",message:"邮箱格式不合法."});
                return;
            }
            this.formData.content = obj.input;
            if(!(this.formData.content && this.formData.content.trim())){
                this.$message({type:"error",message:"内容不能为空."});
                return;
            }
            
            this.$emit("commentBarEmit",{show:false,data:this.formData});
        }
    },
    watch:{
        "commentProp.show"(val){
            this.drawerShow = val;
        },
        "commentProp.email"(val){
            this.formData.email = val;
        }
    },
    components:{CompInput}
}
</script>
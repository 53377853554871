<template>
    <div id="sys-list" v-loading="loading" element-loading-text="数据加载中...">
        <el-row>
            <el-col :span="8"><el-button type="danger" @click="handleDelete">删除</el-button><el-button type="success" @click="showUpload">上传文件</el-button></el-col>
            <el-col :span="16">
                <el-radio-group v-model="fileTypeVal" style="margin-bottom: 20px" @change="fileTypeFilter">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button v-for="itemType in  itemTypes" :key="itemType.itemTypeCode" :label="itemType.itemTypeCode">{{ itemType.itemTypeName }}</el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>

        <el-table :data="items" stripe style="width: 100%;" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" />
            <el-table-column label="缩略图" width="80">
                <template #default="scope">
                    <img v-if="['jpg','png','gif','jpeg','ico'].includes(scope.row.itemSuffix)" class="thumb-img" :src="onProfile(scope.row)">
                    <img v-else-if="['mp3'].includes(scope.row.itemSuffix)" class="thumb-img" src="../../assets/images/mp3.png">
                    <img v-else-if="['mp4'].includes(scope.row.itemSuffix)" class="thumb-img" src="../../assets/images/mp4.png">
                    <img v-else-if="['zip'].includes(scope.row.itemSuffix)" class="thumb-img" src="../../assets/images/zip.png">
                    <img v-else-if="['pdf'].includes(scope.row.itemSuffix)" class="thumb-img" src="../../assets/images/pdf.png">
                    <img v-else-if="['doc','docx'].includes(scope.row.itemSuffix)" class="thumb-img" src="../../assets/images/doc.png">
                    <img v-else-if="['xls','xlsx'].includes(scope.row.itemSuffix)" class="thumb-img" src="../../assets/images/excel.png">
                    <img v-else class="thumb-img" src="../../assets/images/file.png">
                </template>
            </el-table-column>
            <el-table-column label="别名" prop="itemAliasName"/>
            <!-- <el-table-column label="名称" prop="itemName"/> -->
            <el-table-column label="类型" width="80">
                <template #default="scope">
                    {{ toItemTypeName(scope.row.itemType) }}
                </template>
            </el-table-column>
            <el-table-column label="模块" width="120">
                <template #default="scope">
                    {{ toItemTypeName(scope.row.itemSubType) }}
                </template>
            </el-table-column>
            <el-table-column label="后缀" prop="itemSuffix" width="80"/>
            <el-table-column label="启用" width="80">
                <template #default="scope">
                    <el-switch v-model="scope.row.itemState" @change="handleState(scope.row)" size="small" :active-value="1" :inactive-value="0"/>
                </template>
            </el-table-column>
            <el-table-column label="公开" width="80">
                <template #default="scope">
                    <el-switch v-model="scope.row.itemOpenState" @change="handleOpenState(scope.row)" size="small" :active-value="1" :inactive-value="0"/>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="200">
            <template #default="scope">
                <el-button size="small" type="primary" @click="showView(scope.row)">查看</el-button>
                <el-button size="small" type="warning" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button size="small" type="success" @click="copyUid(scope.row.uid)">复制</el-button>
            </template>
            </el-table-column> 
        </el-table>
        <!--分页-->
        <el-pagination :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next, jumper"
        :total="allRows" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        <el-drawer v-model="drawerShow" direction="rtl" @close="drawerClose">
            <template #header><h4>文件上传</h4></template>
                <template #default>
                    <el-form ref="form" :model="formData" label-width="80px">
                        <el-form-item label="标题">
                            <el-input v-model="formData.title"></el-input>
                        </el-form-item>
                        <el-form-item label="公开状态">
                            <el-select v-model="formData.openState" placeholder="选择开放状态">
                                <el-option label="私有" :value="0"/>
                                <el-option label="公开" :value="1"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="文件类型">
                            <el-select v-model="formData.type" placeholder="选择文件类型">
                                <el-option v-for="itemType in  itemTypes" :key="itemType.itemTypeCode" :label="itemType.itemTypeName" :value="itemType.itemTypeCode"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属模块">
                            <el-select v-model="formData.subType" placeholder="选择文件子类型">
                                <el-option v-for="subType in  itemSubTypes" :key="subType.itemTypeCode" :label="subType.itemTypeName" :value="subType.itemTypeCode"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-upload ref="uploadRef" class="upload" action="#" :on-change="onChange" :multiple="true" :auto-upload="false" :limit="uploadMax" :on-exceed="handleExceed">
                                <template #trigger>
                                    <el-button type="primary" class="upload-btn">选择文件</el-button>
                                </template>
                                <el-button class="ml-3" type="success" @click="confirmClick">确认上传</el-button>
                                <!-- <template #tip><div class="el-upload__tip"></div></template> -->
                            </el-upload>
                        </el-form-item>
                    </el-form>
                </template>
        </el-drawer>
        <el-drawer v-model="editDrawerShow" direction="rtl" @close="editDrawerShow=false">
            <template #header><h4>文件类型修改</h4></template>
                <template #default>
                    <el-form ref="editForm" :model="editForm" label-width="80px">
                        <el-form-item label="标题">
                            <el-input readonly v-model="editForm.itemAliasName"></el-input>
                        </el-form-item>
                        <el-form-item label="文件类型">
                            <el-select v-model="editForm.itemType" placeholder="选择文件类型">
                                <el-option v-for="itemType in  itemTypes" :key="itemType.itemTypeCode" :label="itemType.itemTypeName" :value="itemType.itemTypeCode"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属模块">
                            <el-select v-model="editForm.itemSubType" placeholder="选择文件子类型">
                                <el-option v-for="subType in  itemSubTypes" :key="subType.itemTypeCode" :label="subType.itemTypeName" :value="subType.itemTypeCode"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="ml-3" type="success" @click="confirmEditClick">确认修改</el-button>
                        </el-form-item>
                    </el-form>
                </template>
        </el-drawer>
    </div>
</template>
<style>
#sys-list .el-col{text-align: left;}
.thumb-img{max-width: 80%;max-height: 80%;display: flex;border-radius: 5px;}
.upload-btn{margin:20px;}
.el-pagination{position: fixed;bottom: 20px;right: 40px;}
</style>
<script>
export default {
    name:"CompSysList",
    data(){
        return{
            loading:false,items:[],drawerShow:false,editDrawerShow:false,fileTypeVal:"",allTypes:[],itemTypes:[],itemSubTypes:[],
            multipleSelection:[],uploadMax:6,uploadMaxSize:300,files:[],
            fileTypes:["jpg","png","gif","jpeg","doc","docx","ico","md","txt","war","jar","xml","js","css","htm","html","htm","xlsx","xls","pdf","mobi","epub","mp4","mp3","ogg","zip","ttf"],
            formData:{title:"",type:"01",subType:"11",openState:1},
            editForm:{},
            pageNum:1,pageSize:10,allRows:0
        }
    },
    methods:{
        copyUid(uid){
            let oInput = document.createElement('input');     //创建一个隐藏input
			oInput.value = "uid="+uid;    //赋值
			document.body.appendChild(oInput);
			oInput.select(); // 选择对象
			document.execCommand("Copy"); // 执行浏览器复制命令
			oInput.className = 'oInput';
			oInput.style.display='none';
			this.$message({ message: "复制uid成功", type: "success"});
        },
        showView(row){
            if(['doc','docx','xls','xlsx'].includes(row.itemSuffix)){
                window.open("../view?uid="+row.uid,"_blank");
            }else if(['pdf'].includes(row.itemSuffix)){
                window.open("../pdf?uid="+row.uid,"_blank");
            }else{
                window.open(this.onProfile(row),"_blank");
            }
        },
        handleEdit(row){
            this.editDrawerShow = true;
            Object.assign(this.editForm,row);
        },
        confirmEditClick(){
            this.$baseApi({data:{reqPath:'/file/updItemTypeByUid',uid:this.editForm.uid,type:this.editForm.itemType,subType:this.editForm.itemSubType}})
            .then(res=>{
                res.code==1 ? this.$message({type:"success",message:"操作成功."}):this.$message({type:"error",message:res.message});
                this.editDrawerShow = false;
                this.editForm = {};
                this.loadData();
            }).catch(()=>{});
        },
        onProfile(row){
            return `${this.profile}${row.itemSuffix}/${row.itemName}.${row.itemSuffix}`;
        },
        fileTypeFilter(){
            this.loadData();
        },
        toItemTypeName(code){
            return this.allTypes.filter(p=>p.itemTypeCode==code).length>0?this.allTypes.filter(p=>p.itemTypeCode==code)[0].itemTypeName:code;
        },
        drawerClose(){
            this.$refs.uploadRef.clearFiles();
            this.files = [];
        },
        handleExceed(){
            this.$message({type:"error",message:"最多上传"+this.uploadMax+"个文件"});
        },
        onChange(file){
            if (file.size / 1024 / 1024 > this.uploadMaxSize) {
                this.$message({type:"error",message: `上传文件大小不能超过${this.uploadMaxSize}M!`});
                this.$refs.uploadRef.clearFiles();
                return false;
            }
            let fileName = file.name;
            let pos = fileName.lastIndexOf('.')
            let lastName = fileName.substring(pos+1, fileName.length);
            if(!this.fileTypes.includes(lastName.toLowerCase())){
                this.$message.error('不是有效的文件格式.');
                this.$refs.uploadRef.clearFiles();
                return false;
            }
            this.files.push(file);
        },
        handleSizeChange(){
            console.log("handleSizeChange");
        },
        handleCurrentChange(val){
            this.pageNum = val;
            this.loadData();
        },
        handleSelectionChange(val){
            console.log("val",val);
            this.multipleSelection = val;
        },
        showUpload(){
            this.drawerShow = true;
        },
        handleState(row){
            this.$baseApi({data:{reqPath:'/file/updItemState',uid:row.uid,state:row.itemState}})
            .then(res=>{
                res.code==1 ? this.$message({type:"success",message:"操作成功."}):this.$message({type:"error",message:res.message});
                this.loadData();
            }).catch(()=>{});
        },
        handleOpenState(row){
            this.$baseApi({data:{reqPath:'/file/updItemOpenState',uid:row.uid,state:row.itemOpenState}})
            .then(res=>{
                res.code==1 ? this.$message({type:"success",message:"操作成功."}):this.$message({type:"error",message:res.message});
                this.loadData();
            }).catch(()=>{});
        },
        handleDelete(){
            let uids = [];
            this.multipleSelection.forEach(p=>{
                uids.push(p.uid);
            });
            this.$baseApi({data:{uids,reqPath: '/file/delItems'}})
            .then(res=>{
                res.code==1 ? this.$message({type:"success",message:"删除成功."}):this.$message({type:"error",message:res.message});
                this.loadData();
            }).catch(()=>{});
        },
        confirmClick(){
            if(this.files.length<1){
                this.$message({type:"error",message:"上传文件不能为空！"});
                return;
            }
            let formData = new FormData();
        //此处需要和后端接收的参数名相同
            formData.append("title", this.formData.title);
            formData.append("type", this.formData.type);
            formData.append("subType", this.formData.subType);
            formData.append("openState", this.formData.openState);
            // fileList 在data中定义的数组，把所有文件放到formData中
            this.files.forEach(f => {
                formData.append("files", f.raw);
            })
            formData.append("files", this.files);
            this.$baseApi({
                url: '/file/upload',
                method: 'post',
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data:formData
            }).then(res=>{
                this.drawerShow = false;
                if(res.code!=1){
                    this.$message({type:"error",message:res.message});
                    return;
                }
                if(res.data && res.data.details){
                    res.data.details.forEach(p=>{
                        this.$message({type:1==p.code?"success":"error",message:p.message});
                    })
                }
                this.loadData();
            }).catch(()=>{});
        },
        loadData(){
            this.loading = true;
            this.$baseApi({data:{reqPath:'/file/items',type:this.fileTypeVal,subType:"",pageNum:this.pageNum,pageSize:this.pageSize}})
            .then(res=>{
                this.items = res.data.items;
                this.allRows = res.data.rows;
                this.allTypes = res.data.types;
                this.itemTypes = this.allTypes.filter(p=>0==p.subFlag);
                this.itemSubTypes = this.allTypes.filter(p=>0!=p.subFlag);
            }).catch(()=>{}).finally(()=>{this.loading = false;});
        }
    },
    mounted(){
        this.loadData();
    }
}
</script>
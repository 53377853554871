<template>
    <div id="app-main-life" v-loading="loading" element-loading-text="加载中...">
        <comp-life-article v-if="articleShow" @articleEmit="articleEmit" :docId="docId"></comp-life-article>
        <el-timeline v-else class="app-main-life">
            <el-timeline-item v-for="(doc,index) in docs" :key="index" placement="top" icon="Promotion" color="#4FA4FF" size="large">
                <h1>{{ $base.fmtDate(doc.createTime,"MM-dd") }}</h1>
                <i>{{ $base.fmtDate(doc.createTime,"yyyy") }}</i>
                <el-card class="main-life-card" @click.prevent="docCardClick(doc)" shadow="hover">
                    <h2>{{ doc.docTitle }}</h2>
                    <el-tag class="life-card-tag" size="small" type="warning" v-for="(docCode,idx) in doc.docTagCodes" :key="idx">{{ docTagCodeFmt(docCode) }}</el-tag>
                    <p class="life-card-html" v-html="doc.docContent"></p>
                    <div class="life-card-bars">
                        <el-tooltip class="box-item" effect="dark" content="浏览数" placement="top-start" ><span><el-icon color="#39AC6A" size="20"><View /></el-icon><span>{{ doc.docViews }}</span></span></el-tooltip>
                        <el-tooltip class="box-item" effect="dark" content="评论数" placement="top-start" ><span><el-icon color="#39AC6A" size="20"><ChatDotRound /></el-icon><span>{{ doc.docComments }}</span></span></el-tooltip>
                    </div>
                </el-card>
            </el-timeline-item>
        </el-timeline>
        <comp-footer></comp-footer>
    </div>
</template>
<style>
.app-main-life{width: 90%;margin: 20px auto;text-align: left;}
.app-main-life h1{margin: 0;color: #4FA4FF;font-size: 3em;}
.app-main-life i{font-size: 1.4em;}
.app-main-life .life-card-tag{margin-right: 5px;}
.app-main-life .main-life-card{width: 96%;margin: 0 0 0 auto;cursor: pointer;}
.main-life-card .life-card-bars{height: 25px;margin: 10px auto;}
.main-life-card .life-card-html{max-height: 200px;overflow: auto;overflow-x: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 6;}
.life-card-bars span{display: inline-flex;align-items: center;margin-right: 10px;}
</style>
<script>
import CompLifeArticle from './sub/CompLifeArticle.vue';
import CompFooter from "@/components/comm/CompFooter.vue"
export default {
    name:"AppMainLife",
    data(){
        return{
            loading:true,articleShow:false,docId:"",
            allRows:0,pageNum:1,pageSize:2,docs:[],tags:[]
        }
    },
    methods:{
        docTagCodeFmt(code){
            return this.tags.filter(p=>p.tagCode==code)[0].tagName;
        },
        docCardClick(doc){
            this.articleShow = true;
            this.docId = doc.docId;
        },
        articleEmit(val){
            this.articleShow = val;
        },
        lazyLoad(){
            let dom = this.$el;
            let bottomHeight = dom.scrollHeight-dom.scrollTop-dom.clientHeight;
            if(bottomHeight<=200 && this.docs.length<this.allRows){
                ++this.pageNum;
                this.loadData();
            }
        },
        loadData(){
            this.$baseApi({data:{reqPath:"/doc/list",docPosition:1, docState:1,pageNum:this.pageNum,pageSize:this.pageSize}}).then(res=>{
                if(1==res.code){
                    this.docs = this.docs.concat(res.data.docs);
                    this.allRows = res.data.rows;
                    this.tags = res.data.tags;
                }else{
                    this.$message({type:"error",message:"查询异常："+res.message});
                }
            }).catch(()=>{}).finally(()=>{this.loading=false});
        }
    },
    mounted(){
        let that = this;
        this.loadData();
        that.$nextTick(function(){
            that.$el.addEventListener("scroll", that.lazyLoad);
        });
    },
    components:{
        CompLifeArticle,CompFooter
    }
}
</script>
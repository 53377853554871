<template>
  <div id="appDiv">
    <router-view></router-view>
  </div>
</template>
<script>
const debounce = (callback, delay) => {
    let tid;
    return function (...args) {
      const ctx = self;
      tid && clearTimeout(tid);
      tid = setTimeout(() => {
        callback.apply(ctx, args);
      }, delay);
    };
  };

const _ = (window).ResizeObserver;
(window).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};
export default {
  name: "App",
  data() {
    return {
    };
  },
  methods:{
  }
};
</script>

<style>
html,#appDiv{height: 100%;overflow: hidden;}
body{height: calc(100% - 30px);}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
  overflow: hidden;
}
#appDiv .appLiFont *{font-family: liFont;font-weight: normal;}
#appDiv>div{height: 100%;}
#appDiv>div>.el-container{height: 100%;}
.el-button a{color: #FFF;text-decoration: none;}
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  height: 8vh;
  background: #FFF;
  color: #409eff;
  border: 1px solid #EEE;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.grid-content:hover{
    background-image: url(assets/images/pay/select.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60px;
    border: 1px solid #409eff;
}
</style>

import { ElMessage } from 'element-plus'
import emojiList from '@/utils/emoji';
class Topic{
    constructor(name){
        this.topic = name||"topic-default";
        this._handles = new Map();
    }
    //订阅
    subscribe(topic,callback,context){
        if(null==topic) {
            ElMessage.error({message:"订阅主题不能为空."});
            return;
        }
        if(null==callback) {
            ElMessage.error({message:"订阅回调不能为空."});
            return;
        }
        let topicArr = this._handles.get(topic);
        if(!topicArr) this._handles.set(topic,topicArr=[]);
        topicArr.push([callback,context]);
        return "success";
    }
    //发布
    publish(topic,...args){
        console.log("publish",topic);
        if(null==topic) {
            ElMessage.error({message:"发布主题不能为空."});
            return;
        }
        let topicArr = this._handles.get(topic)||[],results=[];
        for(let [callback,context] of topicArr){
            if(null==callback) continue;
            try{
                let result = callback.apply(context,args);
                results.push({state:"success",topic:topic,info:result});
            }catch(e){
                console.error("发布异常",topic,args,callback);
                results.push({state:"fail",topic:topic,info:Promise.reject(e)});
            }
        }
        return results;
    }
}
class Base extends Topic{
    constructor(){
        super("global");
    }
    //解析日期
    parseDate(val) {
        if (val == null || val === "") return null;
        if (val instanceof Date) return val;
        if (typeof val == "string" && !val.includes('T')) { val = val.replace(/-/g, "/"); }
        return new Date(val);
    }
    //格式化日期
    fmtDate(val, fmt) {
        if (fmt == null || fmt === "") return val;
        let date = this.parseDate(val);
        if (date == null || isNaN(date)) return "";

        return fmt.replace(/(y+)|(M+)|(d+)|(H+)|(m+)|(s+)/g, function(match) {
            let v = '';
            switch (match.charAt(0)) {
                case 'y': v = date.getFullYear(); break;
                case 'M': v = date.getMonth() + 1; break;
                case 'd': v = date.getDate(); break;
                case 'H': v = date.getHours(); break;
                case 'm': v = date.getMinutes(); break;
                case 's': v = date.getSeconds(); break;
                default: v = '';
            }
            let val = String(v);
            let vLen = val.length, mLen = match.length;
            if (vLen == mLen) return val;
            if (vLen > mLen) return val.substring(vLen - mLen);
            return val.padStart(mLen, '0');
        });
    }
    //格式化数值，fmt指小数位数
    fmtNumber(val, fmt) {
        if (val == null || val === "") return "";
        if (fmt == null) return val;
        let num = Number(val), fix = parseInt(fmt), suffix = '';
        if (typeof fmt == "string") {
            if (fmt.endsWith('%')) {
                num *= 100; suffix = '%';
            } else if (fmt.endsWith('‰')) {
                num *= 1000; suffix = '‰';
            }
        }
        let str = isNaN(fix) ? String(num) : num.toFixed(fix);
        let pos = str.includes('.') ? str.lastIndexOf('.') : str.length;
        while ((pos -= 3) > 0) {
            if ((pos == 1) && str.charAt(0) === '-') break;
            str = str.substring(0, pos) + "," + str.substring(pos);
        }
        return str + suffix;
    }
    emojiToHtml(content){
        emojiList.forEach(emo=>{
            let idx = content.indexOf(emo.name);
            if(idx>-1){
                let str = content.substring(idx,idx+emo.name.length);
                content = content.replaceAll(str,`<img src="${emo.path}"/>`);
            }
        });
        return content;
    }
}
//websocket
class BaseWebSocket{
    constructor(url,initData){
        this._url = url;
        this._index = 0;
        this.initData = initData;
        this.socket = null;
        this._pingHandle = null;
        this.onCreate();
    }
    //创建
    onCreate(){
        if(this._url) this.socket = new WebSocket(this._url);
    }
    //打开连接
    onOpen(){
        console.log("ws open");
        if(this.initData){
            let sendStr = this.sendDataFmt("init",this.initData);
            let state = this.socket.state;
            if(state==1){
                this.socket.send(sendStr);
            }
        }
        this._pingHandle = setInterval(this.ping(),5*60*1000);
    }
    //接收到消息
    onMessage(data){
        console.log("ws message",data);
    }
    onClose(){
        this.socket.close();
        clearInterval(this._pingHandle);
    }
    onError(data){
        console.log("ws error",data);
    }
    //组装send数据
    sendDataFmt(appId,data){
        return JSON.stringify({
            uuid:this.getUuid(),
            timestamp : (new Date()).getTime(),
            appId:appId,
            userId:"buer",
            data:data 
        });
    }
    getUuid() {
        return "ws_" + (++this._index);
    }
    ping(){
        this.socket.send("ping");
    }
    defaultWebSocket(){
        if(null==this._localWebSocket){
            let initData = null;
            this._localWebSocket = new BaseWebSocket("ws://127.0.0.0:8684",initData);
            return 
        }
        return this._localWebSocket;
    }
}
const base = new Base();
const ws = new BaseWebSocket();
export {base,ws} ;

<template>
    <div id="app-main-guide">
        <el-row v-for="idx in Math.ceil(list.length/colnum)" :key="idx" :gutter="20" style="margin:10px auto;">
            <el-col :span="6" v-for="(one,index) in listFmt(idx)" :key="index">
                <div class="grid-content" @click="guideClick(one)">
                    <div class="grid-content-guide-img"><img class="thumb-img" :src="onProfile(one)"></div>
                    <div class="grid-content-guide-name">{{ one.guideName }}</div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<style>
.grid-content-guide-img{width: 68px;height: 100%;display: flex;justify-content: center;align-items: center;}
.grid-content-guide-img .thumb-img{max-width: 80%;height: 50%;padding:0 10px;}
.grid-content-guide-name{width: calc(100% - 68px);text-align: left;}
</style>
<script>
export default {
    name:"AppMainGuide",
    data(){
        return{
            list:[],colnum:4
        }
    },
    methods:{
        onProfile(row){
            return `${this.profile}png/${row.guideIcon}.png`;
        },
        loadData(){
            this.$baseApi({data:{reqPath:"/sys/guis"}}).then(res=>{
                this.list = res.data.guides;
            }).catch(()=>{});
        },
        guideClick(one){
            window.open(one.guideUrl,1==one.guideType?"_blank":"_self");
        },
        listFmt(idx){
            return this.list.slice((idx-1)*this.colnum,idx*this.colnum);
        }
    },
    mounted(){
        this.loadData();
    },
    components:{
    }
}
</script>
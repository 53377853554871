<template>
    <div class="comp-public">
        <div><small>关注公众号</small></div>
        <div class="gzh"><img src="../../assets/images/gzh.jpg"></div>
    </div>
</template>
<style>
.comp-public{width: 156px;height: 156px;margin:20px auto;z-index: 9;}
.comp-public>div.gzh{width:100%;height: 100%;}
.comp-public>div small{color: #9d9d9d;}
.comp-public>div.gzh img{max-width: 100%;max-height: 100%;}
</style>
<script>
export default {
    name:"CompPublic",
    data(){
        return{
        }
    },
    methods:{
    }
}
</script>
<template>
    <el-container id="app-main-doc" v-loading="loading" element-loading-text="数据加载中...">
        <el-header style="height:120px;">
            <h2>工作文档</h2>
            <el-row :gutter="20">
                <el-col :span="24">关键字搜索&nbsp;&nbsp;<el-input v-model="titleKey" placeholder="项目/标题关键字" style="width:300px;" @keyup="titleSearch"/></el-col>
            </el-row>
        </el-header>
        <el-main style="height: calc(100% - 120px);overflow: auto;">
            <template v-if="list.length>0">
                <el-row class="guide-content-row"  v-for="(one, idx) in list" :key="idx" :gutter="20" style="margin:10px auto;">
                    <el-col :span="24">
                        <div :class="['grid-content' ,1==one.guideType?'grid-content-a':2==one.guideType?'grid-content-b':'grid-content-c']" @click="chooseOne(one)" >
                            <div class="pass-url-copy">
                                <el-tooltip class="box-item" effect="dark" content="复制文档免登录地址" placement="bottom">
                                    <span @click.stop="copyPassUrl(one)">复制</span>
                                </el-tooltip>
                            </div>
                            <div class="grid-content-doc-name">{{ one.guideName }}<small v-if="one.guideDesc" class="grid-content-doc-desc">{{ one.guideDesc }}</small></div>
                            <div :class="['doc-type' ,1==one.guideType?'doc-type-a':2==one.guideType?'doc-type-b':'doc-type-c']">
                                {{ 1==one.guideType?'文档文件':2==one.guideType?'文档链接':3==one.guideType?'操作与问题':'其他' }}
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-drawer v-model="drawerShow" direction="rtl">
                    <template #header><h4>{{ oneQA.guideName }}</h4></template>
                    <template #default>
                        <comp-life-article v-if="drawerShow" :docId="oneQA.guideUrl" :menuLimit="1"></comp-life-article>
                    </template>
                    <template #footer>
                        <div style="flex: auto">
                            <el-button @click="drawerShow = false">关闭</el-button>
                        </div>
                    </template>
                </el-drawer>
            </template>
            <h2 v-else>暂无数据</h2>

        </el-main>
    </el-container>
</template>
<style>
#app-main-doc{height: 100%;}
#app-main-doc .grid-content-doc-name{width: calc(100% - 68px);text-align: left;}
#app-main-doc .grid-content-doc-desc{color: #747474;width: calc(100% - 70px);display: inline-block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-size: 10px;}
#app-main-doc .doc-type{background: orange;border-radius: 5px;font-size: 12px;right: 20px;position: absolute;color: #FFF;padding: 4px 10px;}
#app-main-doc .doc-type-a{background: #409EFF;}
#app-main-doc .doc-type-b{background: #67C23A;}
#app-main-doc .doc-type-c{background: orange;}
#app-main-doc .grid-content:hover{background: unset; box-shadow: 0 0 5px;}
#app-main-doc .grid-content{border: 1px solid #D0D4DE !important;height: 50px;border: 1px solid;}
#app-main-doc .grid-content-a{color: #409EFF;}
#app-main-doc .grid-content-b{color: #67C23A;}
#app-main-doc .grid-content-c{color: orange;}
#app-main-doc .el-drawer{width:100% !important;}
#app-main-doc .el-input__wrapper {border: 1px solid #409EFF;box-shadow: unset;}
#app-main-doc .pass-url-copy{display: none;color: #a19eff;font-size: 12px;position: absolute;left: 10px;}
#app-main-doc .pass-url-copy:hover{text-decoration: underline;}
#app-main-doc .guide-content-row:hover .pass-url-copy{display: block;}
</style>
<script>
import CompLifeArticle from './sub/CompLifeArticle.vue';
export default {
    name:"AppMainDoc",
    data(){
        return{
            loading:false, globleList:[], list:[], colnum:1, titleKey:"", drawerShow:false, oneQA:{}
        }
    },
    methods:{
        copyPassUrl(one){
			let oInput = document.createElement('input');     //创建一个隐藏input
            let execStr = "";
            if(3 == one.guideType){
                execStr = process.env.VUE_APP_BASE_API_URL+"/docs?_uid="+sessionStorage.getItem("uid")+"&_gid="+one.uid;
            }else{
                execStr = one.guideUrl;
            }
			oInput.value = execStr;    //赋值
			document.body.appendChild(oInput);
			oInput.select(); // 选择对象
			document.execCommand("Copy"); // 执行浏览器复制命令
			oInput.className = 'oInput';
			oInput.style.display='none';
			this.$message({ message: "复制成功", type: "success"});
        },
        titleSearch(){
            this.list = this.globleList.filter(p=>p.guideName.indexOf(this.titleKey)>-1 || (p.guideDesc && p.guideDesc.indexOf(this.titleKey)>-1));
        },
        onProfile(row){
            return `${this.profile}png/${row.guideIcon}.png`;
        },
        loadData(gid){
            this.$baseApi({data:{reqPath:"/sys/guides", gid:gid, guideUser:sessionStorage.getItem("uname"), guideType: 0, guideState:1, privateType:1}}).then(res=>{
                console.log("@",res.data);
                this.globleList = res.data.guides;
                this.list = res.data.guides;
            });
        },
        chooseOne(one){
            if([1,2].includes(one.guideType)){
                window.open(one.guideUrl,"_blank");
            }else{
                this.oneQA = one;
                this.drawerShow = true;
            }
        },
    },
    mounted(){
        const localPath = new URL(window.location.href);
        // 使用URLSearchParams接口获取参数
        const localParams = new URLSearchParams(localPath.search);
        // 获取特定参数的值
        this.loadData(localParams.get('_gid'));
    },
    components:{ CompLifeArticle }
}
</script>
<template>
    <div id="app-main-seal" v-loading="loading" element-loading-text="加载中...">
        <comp-oever></comp-oever>
        <h3>共 <b>{{allRows}}</b> 张</h3>
        <div class="view-image" v-for="(item,index) in items" :key="index">
            <div class="view-image-desc">{{ item.itemTitle }} {{ itemTimeFmt(item.createTime) }}</div>
            <el-image v-if="imageFlag(index)" :src="getImgPath(item)" :preview-src-list="[getImgPath(item)]">
                <template #error>
                <div class="image-slot">
                    <el-image :src="require('../assets/images/load-error.png')"></el-image>
                </div>
                </template>
            </el-image>
            <video v-if="!imageFlag(index)" controls autopictureinpicture :src="getImgPath(item)" poster="../assets/images/buer_poster.png"></video>
        </div>
        <comp-footer></comp-footer>
    </div>
</template>
<script>
import CompOever from "@/components/comm/CompOever.vue"
import CompFooter from "@/components/comm/CompFooter.vue"
export default {
    name:"AppMainSeal",
    data(){
        return{
            loading:true,previewPath: require("../assets/images/loading.gif"),
            allRows:0,pageNum:1,pageSize:6,items:[]
        }
    },
    methods:{
        getImgPath(row){
            return `${this.profile}${row.itemSuffix}/${row.itemName}.${row.itemSuffix}`;
        },
        itemTimeFmt(val){
            return this.$base.fmtDate(val,"yyyy-MM-dd HH:mm:ss");
        },
        imageFlag(idx){
            return this.items[idx] && "video"==this.items[idx].type?false:true;
        },
        lazyLoad(){
            let dom = this.$el;
            let bottomHeight = dom.scrollHeight-dom.scrollTop-dom.clientHeight;
            if(bottomHeight<=200 && this.items.length<this.allRows){
                ++this.pageNum;
                this.loadData();
            }
        },
        loadData(){
            this.$baseApi({data:{reqPath:"/file/items",type:"01",subType:"11",state:1,pageNum:this.pageNum,pageSize:this.pageSize}}).then(res=>{
                if(1==res.code){
                    this.items = this.items.concat(res.data.items);
                    this.allRows = res.data.rows;
                }else{
                    this.$message({type:"error",message:"查询异常："+res.message});
                }
            }).catch(()=>{}).finally(()=>this.loading=false);
        }
    },
    mounted(){
        let that = this;
        this.loadData();
        that.$nextTick(function(){
            that.$el.addEventListener("scroll", that.lazyLoad);
        });
    },
    components:{
        CompOever,CompFooter
    }
}
</script>
<style>
h3>b{color: #39ac6a;}
.view-image{width: 50%; margin:10px auto;}
.view-image .el-image,.view-image video{width: 100%;max-height: 100%;}
.el-image-viewer__img{max-height: 90% !important;max-width: 90% !important;}
@media screen and (max-width: 768px) {
    .view-image{width: 80%;}
}
.view-image-desc {z-index: 9;position: relative;top: 20px;color: #39AC6A;padding: 0 5px;text-align: left;}
</style>
<template>
    <div id="comp-tool">
        <div class="comp-tool-bar" v-for="(menu,index) in showMenus" :key="index" :title="menu.name" @click="handleClick(menu.comp)">
            <el-icon><component :is="menu.icon"/></el-icon>
        </div>
        <!-- <div title="音乐" class="comp-tool-bar" @click="musicShow=!musicShow"><el-icon><Headset /></el-icon></div>
        <div title="留言" class="comp-tool-bar" @click="commentProp.show=!commentProp.show"><el-icon><Edit /></el-icon></div>
        <div title="聊天" class="comp-tool-bar" @click="chatShow=!chatShow"><el-icon><ChatDotRound /></el-icon></div>
        <div title="顶部" class="comp-tool-bar" @click="topClick"><el-icon><ArrowUpBold /></el-icon></div>
        <div title="底部" class="comp-tool-bar" @click="bottomClick"><el-icon><ArrowDownBold /></el-icon></div> -->
    </div>
    <comp-comment @commentBarEmit="commentBarEmit" :commentProp="commentProp"></comp-comment>
    <comp-music :musicShow="musicShow"></comp-music>
    <comp-chat @charBarEmit="charBarEmit" :chatShow="chatShow"></comp-chat>
</template>
<style>
#comp-tool-tip{position: fixed;top: 0;left: 180px;height: 18px;width: calc(100% - 200px);display: flex;align-items: center;}
#comp-tool-tip .tool-tip-content{width: 100%;height: 100%;display: flex;overflow: hidden;white-space: nowrap;letter-spacing: 2px;}
#comp-tool-tip .tool-tip-content>small{width: 500%;animation: scroll 20s linear infinite;color: orange;}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
#comp-tool{position: fixed;right: 2vw;bottom: 10vh;}
#comp-tool>div.comp-tool-bar{
    background: #000;
    opacity: 0.7;
    margin: 5px;
    width: 40px;
    height: 40px;
    font-size: 1.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
#comp-tool>div>.el-icon{color:#FFF;}
</style>
<script>
import CompMusic from "@/components/comm/CompMusic.vue"
import CompComment from "./CompComment.vue"
import CompChat from "./CompChat.vue"
export default {
    name:"CompTool",
    props:{
        toolData:{type:Object,default:null}
    },
    data(){
        return{
            musicShow:false,chatShow:false,showMenus:[],
            commentProp:{title:"留言板",show:false}
        }
    },
    emits:["toolEmit"],
    methods:{
        handleClick(method){
            this[`${method}`]();
        },
        musicBarClick(){
            this.musicShow=!this.musicShow;
        },
        commentBarClick(){
            this.commentProp.show=!this.commentProp.show
        },
        chatBarClick(){
            this.chatShow=!this.chatShow;
        },
        topBarClick(){
            this.$emit("toolEmit",{toolbar:"top"});
            // window.scrollTo(0, 0);
        },
        fontBarClick(){
            this.$emit("toolEmit",{toolbar:"font"});
            // window.scrollTo(0, 0);
        },
        bottomBarClick(){
            this.$emit("toolEmit",{toolbar:"bottom"});
            // window.scrollTo(0, document.body.scrollHeight);
        },
        commentBarEmit(val){
            console.log("tool",val);
            this.commentProp.show = val.show;
            if(val.data){
                let commentReq = {reqPath: '/comment/add',contactNo:val.data.email,commentContent:val.data.content,commentType:"sys",orderNo:1};
                this.$baseApi({data:commentReq}).then(res=>{
                    res.data && 1==res.code?
                        this.$message({type:"success",message:"留言成功."}):this.$message({type:"error",message:res.message});
                    this.$emit("toolEmit",{toolbar:"comment",act:"fresh"+this.$base.fmtDate(new Date(),'yyyyMMddHHmmss')});
                }).catch(()=>{});
            }
        },
        charBarEmit(val){
            this.chatShow = val;
        },
        loadMenus(){
            this.$baseApi({data:{reqPath:"/sys/menus",type:"bar"}}).then(res=>{
                this.showMenus = res.data.menus.filter(p=>p.type=="bar" && p.state==1);
            });
        }
    },
    watch:{
        "toolData"(obj){
            let elMain = document.getElementsByClassName("el-main")[0];
            if("top"===obj.toolbar){
                elMain.scrollTo({ top: 0,behavior: 'smooth'});
            }else if("bottom"===obj.toolbar){
                elMain.scrollTo({ top: elMain.scrollHeight,behavior: 'smooth'});
            }
        }
    },
    mounted(){
        this.loadMenus();
    },
    components:{CompMusic,CompComment,CompChat}
}
</script>
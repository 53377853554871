<template>
    <div id="sys-type">
        <el-row>
            <el-col :span="4"><el-button type="success" @click="handleAdd">新增</el-button></el-col>
            <el-col :span="20">
                <el-radio-group v-model="subFlagVal" style="margin-bottom: 20px" @change="subFlagChange">
                    <el-radio-button :label="0">类型</el-radio-button>
                    <el-radio-button :label="1">子类型/模块</el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-table :data="list" stripe>
            <el-table-column label="类型代码" prop="itemTypeCode" width="80"/>
            <el-table-column label="类型名称" prop="itemTypeName"/>
            <el-table-column fixed="right" width="140">
                <template #default="scope">
                    <el-button size="small" type="warning" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-drawer v-model="drawerShow" direction="rtl">
            <template #header><h4>类型{{ addFlag?'新增':'编辑' }}</h4></template>
            <template #default>
                <el-form ref="form" :model="formData" label-width="80px">
                    <el-form-item label="编号">
                        <el-input v-model="formData.itemTypeCode"></el-input>
                    </el-form-item>
                    <el-form-item label="名称">
                        <el-input v-model="formData.itemTypeName"></el-input>
                    </el-form-item>
                    <el-form-item label="类别">
                        <el-select v-model="formData.subFlag">
                            <el-option label="类型" :value="0"/>
                            <el-option label="子类型/模块" :value="1"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="confirmClick">确定</el-button>
                    </el-form-item>
                </el-form>
            </template>
        </el-drawer>
    </div>
</template>
<style>
#sys-type .el-col{text-align: left;}
</style>
<script>
export default {
    name:"CompSysListType",
    data(){
        return{
            drawerShow:false,addFlag:true,subFlagVal:0,
            formData:{},list:[]
        }
    },
    methods:{
        subFlagChange(){
            this.loadData();
        },
        handleAdd(){
            this.drawerShow = true;
            this.addFlag = true;
            this.formData = {subFlag:0};
        },
        handleEdit(row){
            this.drawerShow = true;
            this.addFlag = false;
            Object.assign(this.formData,row);
        },
        handleDelete(row){
            this.$baseApi({
                data:Object.assign({reqPath: '/sys/delType'},row)
            }).then(()=>{
                this.drawerShow = false;
                this.loadData();
            })
        },
        confirmClick(){
            this.$baseApi({
                data:Object.assign({reqPath:this.addFlag?'/sys/addType':'/sys/updType'},this.formData)
            }).then(()=>{
                this.drawerShow = false;
                this.loadData();
            })
        },
        loadData(){
            this.$baseApi({data:{reqPath:"/sys/types",subFlag:this.subFlagVal}}).then(res=>{
                this.list = res.data.types;
            });
        }
    },
    mounted(){
        this.loadData();
    }
}
</script>
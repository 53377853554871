<template>
    <div id="pdfViewer" v-loading="loading" element-loading-text="数据加载中...">
        <div v-show="catalogShow" :class="['pdf-aside',catalogShow?'pdf-aside-show':'']">
            <div class="aside-title">{{ pdfName }}</div>
            <div class="aside-catalog">
                <template v-if="catalogs.length>0">
                    <div :class="['pdf-catalog',prevActiveCatalog == catalog.pageNum ?'current':'']" 
                        v-for="(catalog,index) in catalogs" :key="catalog.uid" @click="onCatalog(catalog,index)">
                        <span :class="[catalog.catalogLevel==1?'catalog-level-1':catalog.catalogLevel==2?'catalog-level-2':'catalog-level-3']">
                            <template v-if="catalog.catalogLevel>1">
                                <span v-for="(level,idx) in (catalog.catalogLevel)" :key="idx">
                                    &nbsp;&nbsp;
                                </span>
                            </template>
                            {{ catalog.catalogTitle }}
                        </span>
                        <i class="catalog-page">{{ catalog.pageNum }}</i>
                    </div>
                </template>
                <template v-else>
                    <h3>暂无目录</h3>
                </template>
            </div>
        </div>
        <el-container>
            <!-- <el-aside :class="['pdf-aside',catalogShow?'pdf-aside-show':'']">
                <el-container>
                    <el-header>{{ pdfName }}</el-header>
                    <el-divider></el-divider>
                    <el-main>
                        <template v-if="catalogs.length>0">
                            <div :class="['pdf-catalog',prevActiveCatalog == catalog.pageNum ?'current':'']" 
                                v-for="(catalog,index) in catalogs" :key="catalog.uid" @click="onCatalog(catalog,index)">
                                <span :class="[catalog.catalogLevel==1?'catalog-level-1':catalog.catalogLevel==2?'catalog-level-2':'catalog-level-3']">
                                    <template v-if="catalog.catalogLevel>1">
                                        <span v-for="(level,idx) in (catalog.catalogLevel)" :key="idx">
                                            &nbsp;&nbsp;
                                        </span>
                                    </template>
                                    {{ catalog.catalogTitle }}
                                </span>
                                <i class="catalog-page">{{ catalog.pageNum }}</i>
                            </div>
                        </template>
                        <template v-else>
                            <h3>暂无目录</h3>
                        </template>
                    </el-main>
                </el-container>
            </el-aside> -->
            <el-main>
                <el-tooltip v-if="!catalogShow" class="box-item" effect="dark" content="打开目录" placement="bottom-start" ><el-icon class="el-icon-show" @click="catalogShow=!catalogShow"><Expand /></el-icon></el-tooltip>
                <el-tooltip v-else class="box-item" effect="dark" content="收起目录" placement="bottom-start" ><el-icon class="el-icon-show el-icon-fold " @click="catalogShow=!catalogShow"><Fold /></el-icon></el-tooltip>
                <div class="pdf-page"><b>{{ currentPage }}/{{ totalPages }}</b></div>
                <div class="pdf-toolbar">
                    <el-tooltip class="box-item" effect="dark" content="上一页" placement="top-start" ><el-button type="primary" size="small" @click="onPrev()">Prev</el-button></el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="下一页" placement="bottom-start" ><el-button type="primary" size="small" @click="onNext">Next</el-button></el-tooltip>
                    <div>
                        <el-tooltip class="box-item" effect="dark" content="放大" placement="bottom-start" >
                            <el-tag @click="pdfImgSize(true)" type="success" size="small" effect="plain">
                                <el-icon><Plus /></el-icon>
                            </el-tag>
                        </el-tooltip>
                        <el-tooltip class="box-item" effect="dark" content="缩小" placement="bottom-start" >
                            <el-tag @click="pdfImgSize(false)" type="warning" size="small" effect="plain">
                                <el-icon><Minus /></el-icon>
                            </el-tag>
                        </el-tooltip>
                    </div>
                </div>
                <div class="pdf-content" v-if="item.uid">
                    <img class="pdf-img" ref="pdfImgRef" :src="onProfile()">
                </div>
            </el-main>
        </el-container>
    </div>
</template>
<style scoped>
#pdfViewer{display: flex;}
.pdf-aside{position: relative;width: 0;transition: width 1s ease-in-out;border-right: 1px solid #EEE;}
.pdf-aside.pdf-aside-show{width: 300px;height: 100%;overflow: hidden;position: absolute;top: 0;left: 0;z-index: 9;background: #FFF;}
.pdf-aside .aside-title{width: 100%;height: 60px;line-height: 60px;color:#409EFF;font-weight: bold;font-size: 1.6em;}
.pdf-aside .aside-catalog{width:100%;height: calc(100% - 60px);overflow: auto;}
.el-main{position: relative;}
.el-main .el-icon-show{position: fixed;left:0;top:20px;cursor: pointer;font-size: 1.6em;z-index:9;color: #409EFF;background: #fff;padding: 0;}
.el-main .el-icon-show.el-icon-fold{left: 301px;}
.catalog-level-1{font-weight: bold;font-size: 14px;}
.catalog-level-2{font-weight: bold;font-size: 12px;}
.catalog-level-3,.catalog-page{font-size: 12px;}
.pdf-catalog{display: flex;padding: 0px 20px 5px 15px;justify-content: space-between;cursor: pointer;}
.pdf-catalog:hover,.pdf-catalog.current{color: #3A9AF3;}
.pdf-page{color:#67C23A;position: fixed;top: 15px;right: 5px;font-size: 1.2em;margin: 5px;}
.pdf-toolbar{position: fixed;top: 50px;right: 5px;}
.pdf-toolbar .el-button{display: flex;margin: 10px 5px;}
.pdf-content{width: 100%;height: 100%;}
@media screen and (min-width:900px) {
    .pdf-content>img.pdf-img{width:60%;max-width: 140%;min-width: 20%;}
}
@media screen and (max-width:900px) {
    .pdf-content>img.pdf-img{width:96%;max-width: 140%;min-width: 20%;}
}
</style>
<script>
export default {
    name:"CompBuerPDFView",
    data(){
        return{
            loading:false,initFlag:false,catalogs:[],item:{},totalPages:0,pdfName:"",
            currentPage:1,prevActiveCatalog:0,catalogShow:false
        }
    },
    methods:{
        onProfile(){
            return `${this.profile}pdf/${this.pdfName}/${this.item.itemAliasName}.png`;
        },
        pdfImgSize(large){
            let wd = this.$refs.pdfImgRef.width;
            if(large) this.$refs.pdfImgRef.style.width = wd*1.2+"px";
            if(!large) this.$refs.pdfImgRef.style.width = wd*0.8+"px";
        },
        onCatalog(catalog,index){
            this.currentPage = catalog.pageNum;
            this.calcPrevActive();
            let pageStart = catalog.pageNum;
            let pageEnd = index+1<this.totalPages?this.catalogs[index+1].pageNum:this.totalPages;
            this.pdfView(pageStart,pageEnd);
        },
        onPrev(){
            if(this.currentPage<2){
                this.$e({ e: "当前已是首页.", type: "error"});
                return;
            }
            this.currentPage = this.currentPage - 1;
            this.calcPrevActive();
            this.pdfView(this.currentPage,this.currentPage);
        },
        onNext(){
            if(this.currentPage+1 > this.totalPages){
                this.$e({ e: "当前已是尾页.", type: "error"});
                return;
            }
            this.currentPage = this.currentPage + 1;
            this.calcPrevActive();
            this.pdfView(this.currentPage,this.currentPage);
        },
        calcPrevActive(){
            this.prevActiveCatalog = 0;
            if(this.catalogs.length>0){
                let tempArr = this.catalogs.filter(p=>p.pageNum<=this.currentPage);
                this.prevActiveCatalog = tempArr.length>0?this.catalogs[tempArr.length-1].pageNum:this.catalogs[0].pageNum;
            }
        },
        pdfView(pageStart,pageEnd){
            this.loading = true;
            this.$baseApi({data:{reqPath:'/file/pdfbox',uid:this.$route.query.uid,pageStart:pageStart,pageEnd:pageEnd}})
            .then(res=>{
                this.catalogs = res.data.catalogs;
                this.item = res.data.items[0];
                this.pdfName = res.data.pdfName;
                this.totalPages = res.data.totalPages;
            }).catch(()=>{}).finally(()=>{this.loading = false;});
        }
    },
    mounted(){
        this.pdfView(1,1);
    }
}
</script>
<template>
    <el-container direction="vertical" :class="['app-container',liFontFlag?'appLiFont':'']">
        <el-header>
            <div id="header-logo">
                <img class="header-logo" src="../assets/images/logo.png" alt="logo">
            </div>
            <div id="header-menu">
                <div style="align-self: center;margin-left: 25%;font-size: 1.6em;color:#54A8FF">{{ activeMenuName }}</div>
                <img @click="headerMenuIconClick" v-if="menuIconFlag" class="header-menu-icon" src="../assets/images/menu-expand.png" alt="menu-expand">
                <img @click="headerMenuIconClick" v-else class="header-menu-icon" src="../assets/images/menu-fold.png" alt="menu-fold">
            </div>
            <el-menu class="menu-horizontal" :default-active="workComp" mode="horizontal" @select="handleSelect">
                <el-menu-item v-for="(menu,index) in showMenus" :key="index" :index="menu.comp">{{ menu.name }}</el-menu-item>
            </el-menu>
            <el-menu v-if="!menuIconFlag" class="menu-vertical" :default-active="workComp" @select="handleMiniSelect">
                <el-menu-item v-for="(menu,index) in showMenus" :key="index" :index="menu.comp">{{ menu.name }}</el-menu-item>
            </el-menu>
        </el-header>
        <el-main class="container-main">
            <component ref="compRef" :is="workComp" :dataProp="dataProp" class="container-main-comp"></component>
            <comp-tool @toolEmit="toolEmit" :toolData="toolData"></comp-tool>
        </el-main>
    </el-container>
    
</template>
<script>
import CompMainSeal from "@/components/AppMainSeal.vue"
import CompMainBook from "@/components/AppMainBook.vue"
import CompMainMore from "@/components/AppMainMore.vue"
import CompMainAbout from "@/components/AppMainAbout.vue"
import CompMainLife from "@/components/AppMainLife.vue"
import CompMainBoard from "@/components/AppMainBoard.vue"
import CompMainGuide from "@/components/AppMainGuide.vue"
import CompTool from "@/components/comm/CompTool.vue"
export default {
    name:"CompMain",
    data(){
        return{
            liFontFlag:true,menuIconFlag:true,
            workComp:"comp-main-seal",activeMenuName:"",toolData:null,showMenus:[],dataProp:null
        }
    },
    emits:["compEmit","stepEmit"],
    methods:{
        loadMenus(){
            this.$baseApi({data:{reqPath:"/sys/menus",type:"index"}}).then(res=>{
                this.showMenus = res.data.menus.filter(p=>p.type=="index" && p.state==1);
                this.activeMenuName = this.showMenus[0].name;
            }).catch(()=>{});
        },
        handleSelect(comp){
            this.workComp = comp;
            this.activeMenuName = this.showMenus.filter(p=>p.comp==this.workComp)[0].name;
        },
        handleMiniSelect(comp){
            this.menuIconFlag = true;
            this.handleSelect(comp);
        },
        headerMenuIconClick(){
            this.menuIconFlag = !this.menuIconFlag;
        },
        toolEmit(obj){
            this.toolData = obj;
            if(obj.toolbar == 'top'){
                this.$refs.compRef.$el.scrollTo({left:0,top:0,behavior:'smooth'});
            }else if(obj.toolbar == 'bottom'){
                this.$refs.compRef.$el.scrollTo({left:0,top:this.$refs.compRef.$el.scrollHeight,behavior:'smooth'});
            }else if(obj.toolbar == 'font'){
                this.liFontFlag = !this.liFontFlag;
            }else if(obj.toolbar == 'comment'){
                this.workComp = "comp-main-board";
                this.dataProp={toolbar:"comment",act:obj.act};
            }
        },
        handleResize(){
            let winWidth = window.innerWidth;
            if(winWidth>900){
                this.menuIconFlag = true;
            }
        }
    },
    mounted(){
        this.loadMenus();
        window.addEventListener("resize", this.handleResize);
    },
    components:{
        CompMainSeal,CompMainBook,CompMainAbout,CompMainMore,
        CompTool,CompMainLife,CompMainBoard,CompMainGuide
    }
}
</script>
<style>
.app-container{height: 100%;}
.container-main{height: 100%;overflow: hidden;padding-bottom: 0;}
.container-main .container-main-comp{height: 100%;overflow: auto;}
#header-logo{float: left;height: 120%;}
#header-logo img{max-height: 80%;}
.app-container .menu-vertical {display: block;z-index: 99;opacity: 0.9;padding: 20px;border: none;}
.app-container .menu-vertical li{text-align: center;}
#full-width-btn{width: 100%;}
.el-drawer{width:40% !important;}
.el-drawer .el-drawer__header{margin-bottom: 0;}
@media screen and (max-width:900px) {
    .el-drawer{width:80% !important;}
}
#header-menu,.menu-horizontal{display: none;}
@media screen and (min-width:900px) {
    .menu-horizontal{display: flex;}
    #header-menu{display: none;}
}
@media screen and (max-width:900px) {
    #header-menu{display: flex;justify-content: space-between;;height: 100%;align-content: center;}
    #header-menu img{width: 20px;height: 20px;align-self: center;cursor: pointer;}
    .menu-horizontal{display: none;}
}
</style>
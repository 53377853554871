<template>
    <div id="guide-bg"></div>
    <div id="app-login">
        <div class="app-login-desc"><img src="../assets/images/buer02.png"></div>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="用户名">
                <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button id="login-btn" type="primary" @click="login">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<style>
#guide-bg{
    background-image: url(../assets/images/background.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 100%;
}
#app-login{
    width: 300px;
    height: 500px;
    position: absolute;
    right: 15vw;
    top: 25vh;
}
.app-login-desc{
    width: 166px;
    height: 66px;
    margin: 10px auto;
}
.app-login-desc>img{
    max-width: 80%;
}
#login-btn{
    width: 100%;
}
</style>
<script>
export default {
    name:"AppLogin",
    data(){
        return{
            form:{
                username:"",password:""
            }
        }
    },
    methods:{
        login(){
            if(!(this.form.username && this.form.username.trim())){
                this.$message({type:"error",message:"用户名不能为空."});
                return ;
            }
            if(!(this.form.username && this.form.username.trim())){
                this.$message({type:"error",message:"用户名不能为空."});
                return ;
            }
            this.$baseApi({data:Object.assign({reqPath:"/login/to"},this.form)}).then(res=>{
                console.log("res",res);
                if(res.data && res.data.uid){
                    this.$message({type:"success",message:"登录成功."});
                    sessionStorage.setItem("uid",res.data.uid);
                    sessionStorage.setItem("guideDoc",res.data.guideDoc);
                    sessionStorage.setItem("uname",res.data.username);
                    if(res.data.guideDoc && "0" == res.data.guideDoc){
                        this.$router.push("../sys");
                    }else{
                        this.$router.push("../docs");
                    }
                }else{
                    this.$message({type:"error",message:"登录失败："+res.message});
                }
            }).catch((err)=>{console.log("err",err)});
        }
    }
}
</script>
<template>
    <el-card class="life-detail-card" v-loading="loading" element-loading-text="数据加载中...">
        <div class="detail-card-back" v-if="1!=menuLimit"><el-tooltip class="box-item" effect="dark" content="返回" placement="right" ><el-icon size="40" color="#39AC6A" @click="onBack"><Back /></el-icon></el-tooltip></div>
        <h2>{{ doc.docTitle }}</h2>
        <div class="detail-card-tag">
            <el-tag effect="light"><el-tooltip class="box-item" effect="dark" content="时间" placement="top-start" ><span><el-icon color="orange" size="20"><Timer /></el-icon><span>{{ $base.fmtDate(doc.createTime,'yyyy-MM-dd HH:mm:ss') }}</span></span></el-tooltip></el-tag>
            <el-tag effect="light" style="margin:0 5px;"><el-tooltip class="box-item" effect="dark" content="浏览数" placement="top-start" ><span><el-icon color="orange" size="20"><View /></el-icon><span>{{ doc.docViews }}</span></span></el-tooltip></el-tag>
            <el-tag effect="light" @click="commentClick"><el-tooltip class="box-item" effect="dark" content="评论" placement="top-start" ><span><el-icon color="orange" size="20"><ChatDotRound /></el-icon><span>{{ doc.docComments }}</span></span></el-tooltip></el-tag>
        </div>
        <div class="ql-container ql-snow">
            <div class="ql-editor">
                <div v-html="doc.docContent"></div>
            </div>
        </div>
    </el-card>
    <el-drawer v-model="drawerShow" direction="rtl">
        <template #header><h4>评论区</h4></template>
        <template #default>
            <comp-input :inputProp="inputProp" @compInputEmit="compInputEmit" style="position: fixed;height: 90px;width: 90%;"></comp-input>
            <comp-board :menuLimit="menuLimit" :dataProp="dataProp" style="height: calc(100% - 100px); margin-top: 100px;overflow: auto;"></comp-board>
        </template>
    </el-drawer>
</template>
<style>
.life-detail-card{width: 90%;margin: auto;}
.life-detail-card span{display: inline-flex;align-items: center;}
.life-detail-card .detail-card-tag,.life-detail-card .detail-card-back{text-align: right;cursor: pointer;}
.life-detail-card .ql-container{border:none;height: auto;}
.life-detail-card .ql-container img{max-width: 80% !important;}
</style>
<script>
import CompInput from "../comm/CompInput.vue";
import CompBoard from "@/components/AppMainBoard.vue"
export default {
    name:"CompLifeArticle",
    props:{
        docId:{type:String, default:""},
        menuLimit:{type:Number, default:0}
    },
    emits:['articleEmit'],
    data(){
        return{
            loading:false, drawerShow:false,inputProp:{name:"发表评论",rows:2},doc:{},
            dataProp:{},compInputData:null
        }
    },
    methods:{
        compInputEmit(obj){
            this.compInputData = obj;
            if(!(this.compInputData && this.compInputData.input.trim())){
                this.$message({type:"error",message:"内容不能为空."});
                return;
            }
            let commentReq = {reqPath: '/comment/add', passFlag:this.menuLimit, contentId:this.docId,commentContent:this.compInputData.input,commentType:"doc"};
            this.$baseApi({data:commentReq}).then(res=>{
                res.data && 1==res.code?
                this.$message({type:"success",message:"留言成功."}):this.$message({type:"error",message:res.message});
                this.dataProp={uid:this.docId,toolbar:"comment",act:"fresh"+this.$base.fmtDate(new Date(),'yyyyMMddHHmmss')};
                this.drawerShow = false;
            }).catch(()=>{});
        },
        onBack(){
            this.$emit("articleEmit",false);
        },
        commentClick(){
            this.drawerShow = true;
            Object.assign(this.dataProp,{type:"doc",uid:this.docId});
        },
        loadInfo(){
            this.loading = true;
            this.$baseApi({data:{reqPath:"/doc/info",docId:this.docId}}).then(res=>{
                if(1==res.code){
                    this.doc = res.data.doc;
                }else{
                    this.$message({type:"error",message:"查询异常："+res.message});
                }
            }).catch(()=>{}).finally(()=>this.loading = false);
        }
    },
    mounted(){
        this.loadInfo();
    },
    components:{CompInput,CompBoard}
}
</script>
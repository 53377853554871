<template>
    <div class="app-view" v-loading="loading" element-loading-text="数据加载中...">
        <div class="app-view-title">{{ item.itemAliasName }}</div>
        <VueOfficeDocx class="app-view-office" v-if="['docx','doc'].includes(item.itemSuffix)" :src="onProfile()" @rendered="rendered"></VueOfficeDocx>
        <VueOfficeExcel class="app-view-office"  v-if="['xlsx','xls'].includes(item.itemSuffix)" :src="onProfile()" @rendered="rendered"></VueOfficeExcel>
        <!-- <VueOfficePdf class="app-view-office" v-if="['pdf','txt'].includes(item.itemSuffix)" :src="onProfile()" @rendered="rendered"></VueOfficePdf> -->
    </div>
</template>
<style>
.app-view .app-view-title{width: 100%;position: fixed;top: -10px;background: #808080;padding:20px 10px 10px 10px;color: #FFF;z-index: 9;}
.app-view .app-view-office{height: 100%;margin-top: 20px;}
.app-view>div section{width: 90% !important;min-height: auto !important;padding:40pt 60pt !important;}
.app-view .vue-office-docx-main .docx-wrapper{padding-bottom: 30px ;}
</style>
<script>
//引入VueOfficeDocx组件
import VueOfficeDocx from '@vue-office/docx'
//引入VueOfficePdf组件
/* import VueOfficePdf from '@vue-office/pdf' */
//引入VueOfficeExcel组件
import VueOfficeExcel from '@vue-office/excel'
//引入相关样式
import '@vue-office/excel/lib/index.css'
//引入相关样式
import '@vue-office/docx/lib/index.css'
export default {
    name:"CompVueOfficeView",
    data(){
        return{
            loading:true,item:{}
        }
    },
    methods:{
        onProfile(){
            return `${this.profile}${this.item.itemSuffix}/${this.item.itemName}.${this.item.itemSuffix}`;
        },
        rendered(){
            this.loading = false;
        },
        loadData(){
            this.$baseApi({data:{reqPath:'/file/view',uid:this.$route.query.uid}})
            .then(res=>{
                this.item = res.data.item;
                if(['pdf'].includes(this.item.itemSuffix)){
                    window.open("pdf?uid="+this.$route.query.uid,"_self");
                }
            }).catch(()=>{});
        }
    },
    components:{
        VueOfficeDocx,VueOfficeExcel
    },
    mounted(){
        this.loadData();
    }
}
</script>
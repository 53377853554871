import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus,{ElMessage} from 'element-plus'
import * as ElementPlusIcons from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/index.css'
import '@/assets/font/buer-font.css'

// quill编辑器基本使用所需的import
import {QuillEditor, Quill } from '@vueup/vue-quill' // 导入 VueQuillEditor，不带 {}
import imageResize from 'quill-image-resize-module';
import 'quill-image-resize-module/image-resize.min.js';

// import QuillBetterTable from 'quill-better-table'; // 需要升级 quill 版本 2.0 以上
// import "quill-better-table/dist/quill-better-table.css";

Quill.register('modules/imageResize', imageResize);
// Quill.register({'modules/better-table': QuillBetterTable}, true)
// 新增：导入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import router from './router'
import {base} from './utils/base'
import baseApi from "@/utils/baseApi";

const app = createApp(App);
app.use(ElementPlus);
app.use(router);
// 全局注册el-icon
for (const [name, component] of Object.entries(ElementPlusIcons)) {
    app.component(name, component);
}
app.component("quill-editor", QuillEditor);
// 将消息提示组件注册为全局方法
app.config.globalProperties.$message = ElMessage;
app.config.globalProperties.$base = base;
app.config.globalProperties.$baseApi = baseApi;
app.config.globalProperties.profile = process.env.VUE_APP_PROFILE_URL;
app.mount('#app');

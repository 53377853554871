<template>
    <div class="guide-desc">
        <div><span>O ever youthful,O ever weeping</span></div>
        <div><small>永远年轻，永远热泪盈眶。</small></div>
    </div>
</template>
<style>
.guide-desc>div{margin: 0 auto;}
.guide-desc span{font-weight: bold;font-size: 1.6em;}
.guide-desc small{color: #9d9d9d;}
</style>
<script>
export default {
    name:"CompOever",
    data(){
        return{
            fileList:[]
        }
    },
    methods:{
        submitUpload(){
            console.log("submitUpload");
        }
    }
}
</script>
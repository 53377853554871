const emojiList = [
    {name:"[心]",path:require('../assets/images/emoji/aini.gif')},
    {name:"[拜拜]",path:require('../assets/images/emoji/baibai.gif')},
    {name:"[白眼]",path:require('../assets/images/emoji/baiyan.gif')},
    {name:"[抱抱]",path:require('../assets/images/emoji/baobao.gif')},
    {name:"[悲伤]",path:require('../assets/images/emoji/beishang.gif')},
    {name:"[鄙视]",path:require('../assets/images/emoji/bishi.gif')},
    {name:"[闭嘴]",path:require('../assets/images/emoji/bizui.gif')},
    {name:"[吃惊]",path:require('../assets/images/emoji/chijing.gif')},
    {name:"[蛋糕]",path:require('../assets/images/emoji/dangao.gif')},
    {name:"[发红包]",path:require('../assets/images/emoji/fahongbao.gif')},
    {name:"[愤怒]",path:require('../assets/images/emoji/fennu.gif')},
    {name:"[浮云]",path:require('../assets/images/emoji/fuyun.gif')},
    {name:"[感冒]",path:require('../assets/images/emoji/ganmao.gif')},
    {name:"[给力]",path:require('../assets/images/emoji/geili.gif')},
    {name:"[好]",path:require('../assets/images/emoji/good.gif')},
    {name:"[鼓掌]",path:require('../assets/images/emoji/guzhang.gif')},
    {name:"[哈哈]",path:require('../assets/images/emoji/haha.gif')},
    {name:"[害羞]",path:require('../assets/images/emoji/haixiu.gif')},
    {name:"[汗]",path:require('../assets/images/emoji/han.gif')},
    {name:"[哈欠]",path:require('../assets/images/emoji/haqian.gif')},
    {name:"[黑线]",path:require('../assets/images/emoji/heixian.gif')},
    {name:"[哼]",path:require('../assets/images/emoji/heng.gif')},
    {name:"[话筒]",path:require('../assets/images/emoji/huatong.gif')},
    {name:"[互粉]",path:require('../assets/images/emoji/hufen.gif')},
    {name:"[囧]",path:require('../assets/images/emoji/jiong.gif')},
    {name:"[挤眼]",path:require('../assets/images/emoji/jiyan.gif')},
    {name:"[可爱]",path:require('../assets/images/emoji/keai.gif')},
    {name:"[可怜]",path:require('../assets/images/emoji/kelian.gif')},
    {name:"[哭]",path:require('../assets/images/emoji/ku.gif')},
    {name:"[困]",path:require('../assets/images/emoji/kun.gif')},
    {name:"[来]",path:require('../assets/images/emoji/lai.gif')},
    {name:"[蜡烛]",path:require('../assets/images/emoji/lazhu.gif')},
    {name:"[泪]",path:require('../assets/images/emoji/lei.gif')},
    {name:"[No]",path:require('../assets/images/emoji/no.gif')},
    {name:"[怒]",path:require('../assets/images/emoji/nu.gif')},
    {name:"[怒骂]",path:require('../assets/images/emoji/numa.gif')},
    {name:"[OK]",path:require('../assets/images/emoji/ok.gif')},
    {name:"[钱]",path:require('../assets/images/emoji/qian.gif')},
    {name:"[亲亲]",path:require('../assets/images/emoji/qinqin.gif')},
    {name:"[弱]",path:require('../assets/images/emoji/ruo.gif')},
    {name:"[色]",path:require('../assets/images/emoji/se.gif')},
    {name:"[伤心]",path:require('../assets/images/emoji/shangxin.gif')},
    {name:"[生病]",path:require('../assets/images/emoji/shengbing.gif')},
    {name:"[神马]",path:require('../assets/images/emoji/shenma.gif')},
    {name:"[失望]",path:require('../assets/images/emoji/shiwang.gif')},
    {name:"[衰]",path:require('../assets/images/emoji/shuai.gif')},
    {name:"[书呆子]",path:require('../assets/images/emoji/shudaizi.gif')},
    {name:"[睡]",path:require('../assets/images/emoji/shui.gif')},
    {name:"[思考]",path:require('../assets/images/emoji/sikao.gif')},
    {name:"[太开心]",path:require('../assets/images/emoji/taikaixin.gif')},
    {name:"[偷笑]",path:require('../assets/images/emoji/touxiao.gif')},
    {name:"[吐]",path:require('../assets/images/emoji/tu.gif')},
    {name:"[挖鼻]",path:require('../assets/images/emoji/wabi.gif')},
    {name:"[围观]",path:require('../assets/images/emoji/weiguan.gif')},
    {name:"[委屈]",path:require('../assets/images/emoji/weiqu.gif')},
    {name:"[威武]",path:require('../assets/images/emoji/weiwu.gif')},
    {name:"[微笑]",path:require('../assets/images/emoji/weixiao.gif')},
    {name:"[嘘]",path:require('../assets/images/emoji/xu.gif')},
    {name:"[耶]",path:require('../assets/images/emoji/ye.gif')},
    {name:"[阴险]",path:require('../assets/images/emoji/yinxian.gif')},
    {name:"[疑问]",path:require('../assets/images/emoji/yiwen.gif')},
    {name:"[赞]",path:require('../assets/images/emoji/zan.gif')},
    {name:"[猪]",path:require('../assets/images/emoji/zhu.gif')},
    {name:"[左哼哼]",path:require('../assets/images/emoji/zuohengheng.gif')},
    {name:"[右哼哼]",path:require('../assets/images/emoji/youhengheng.gif')},
    {name:"[抓狂]",path:require('../assets/images/emoji/zhuakuang.gif')}
];
export default emojiList;
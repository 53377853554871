import axios from 'axios'
import { ElMessage} from 'element-plus';
var CryptoJS = require("crypto-js");
// 创建axios实例
const baseApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL, // api 的 base_url
  timeout: 200000, // 请求超时时间
  url:process.env.VUE_APP_API_URL,
  method:"post",
  data:{}
})

// request拦截器
baseApi.interceptors.request.use(config => {
  // Do something before request is sent
  if(!config.url){
    let preEncData = JSON.stringify(config.data);
    config.url = process.env.VUE_APP_API_URL;
      config.headers['user-buer'] = baseApi.encrypt_md5(preEncData);
      if(config.data){
        config.data = {};
        config.data.encData = baseApi.encrypt(preEncData);
        config.data.timestamp = new Date().getTime();
      }
    }
    return config;
  },error => {
    // Do something with request error
    return Promise.reject(error);
  });
  
  //response拦截器
  baseApi.interceptors.response.use(
    response => {
      const res = response.data
      if (res.code < 0) {
        ElMessage({message: res.message,type: 'error'})
        return Promise.reject('error')
      } else {
        let result = Object.assign({},{code:res.code,message:res.message});
        if(res && res.encData){
          result.data = JSON.parse(baseApi.decrypt(res.encData));
        }else{
          result.data = res.data;
        }
        return result;
      }
    },error => {
      ElMessage({message: "【"+error.response.status+"】"+error.response.data.message||error.message,type: 'error'})
      return Promise.reject(error)
    }
)
const aaa = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPT_KEY);
const bbb = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPT_IV);
baseApi.encrypt=(val)=>{
  // Encrypt
  let srcs = CryptoJS.enc.Utf8.parse(val);
  let encrypted = CryptoJS.AES.encrypt(srcs, aaa, {
      iv: bbb ,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}
baseApi.decrypt=(val)=>{
  // Decrypt
  let base64  = CryptoJS.enc.Base64.parse(val);
  let srcs = CryptoJS.enc.Base64.stringify(base64);
  const decrypt = CryptoJS.AES.decrypt(srcs, aaa, {
      iv: bbb ,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
}
baseApi.encrypt_md5 = (val)=>{
  return CryptoJS.MD5(val);
}
export default baseApi
<template>
    <div id="sys-dom">
        <el-row>
            <el-col :span="8">
                <el-button type="danger" @click="handleDelete">删除</el-button>
                <el-button type="success" @click="showDrawer(null,true)">创作</el-button>
            </el-col>
            <el-col :span="16">
                <el-radio-group v-model="docTypeVal" style="margin-bottom: 20px" @change="docTypeFilter">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button v-for="(type,index) in docTypes" :key="index" :label="type.key">{{ type.value }}</el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-table :data="list" stripe style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" />
            <el-table-column label="日期" width="100">
                <template #default="scope">{{ docDateFmt(scope.row.createTime) }}</template>
            </el-table-column>
            <el-table-column label="分类" width="100">
                <template #default="scope">{{ docTypeFmt(scope.row.docType) }}</template>
            </el-table-column>
            <el-table-column label="标题" prop="docTitle" />
            <el-table-column label="ID" prop="docId" />
            <el-table-column label="状态" prop="docState" width="80">
                <template #default="scope">
                    <el-tag v-if="1==scope.row.docState" type="success">公开</el-tag>
                    <el-tag v-else type="warning">私有</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="位置" prop="docPosition" width="80">
                <template #default="scope">
                    <el-tag v-if="1==scope.row.docPosition" type="success">独立</el-tag>
                    <el-tag v-else type="warning">内嵌</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="标签" width="140">
                <template #default="scope">
                    <div class="dom-tags-content">
                    <el-tag v-for="(tagCode,index) in scope.row.docTagCodes" size="small" :key="index" type="success">
                        {{ docTagFmt(tagCode) }}&nbsp;
                    </el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed="right" width="140">
                <template #header>
                    <el-input v-model="titleFilter" placeholder="标题搜索"></el-input>
                </template>
                <template #default="scope">
                    <el-button size="small" type="primary" @click="commentDom(scope.row)">评论</el-button>
                    <el-button size="small" type="warning" @click="showDrawer(scope.row,false)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next, jumper"
            :total="totalRows" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        <!--评论管理-->
        <el-drawer v-model="drawerShow" direction="rtl">
            <template #header><h4>评论管理</h4></template>
            <template #default>
                <comp-board></comp-board>
            </template>
        </el-drawer>
        <!--创作document-->
        <el-drawer v-model="domShow" direction="rtl">
            <template #header><h4>发表文档</h4></template>
            <template #default>
                <el-form :model="formData">
                    <el-form-item label="标题">
                        <el-input size="small" v-model="formData.docTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="标签">
                        <el-tag  size="small" style="cursor: pointer;" v-for="(tag,idx) in tags" :key="idx" :type="formData.docTagCodes.includes(tag.tagCode)?'success':'info'" effect="plain" @click="tagClick(tag)">{{ tag.tagName }}</el-tag>
                    </el-form-item>
                    <el-form-item>
                        <el-row :gutter="20">
                            <el-col :span="8">
                                分类&nbsp;<el-select  size="small" v-model="formData.docType" placeholder="分类">
                                    <el-option v-for="(docType,index) in docTypes" :key="index" :label="docType.value" :value="docType.key"/>
                                </el-select>
                            </el-col>
                            <el-col :span="8">
                                状态&nbsp;<el-select size="small" v-model="formData.docState" placeholder="状态">
                                    <el-option label="公开" :value="1"/>
                                    <el-option label="私有" :value="0"/>
                                </el-select>
                            </el-col>
                            <el-col :span="8">
                                位置&nbsp;<el-select size="small" v-model="formData.docPosition" placeholder="位置">
                                        <el-option label="外部独立" :value="1"/>
                                        <el-option label="其他内嵌" :value="0"/>
                                    </el-select>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <div class="dom-quill-div">
                            <quill-editor id="dom-quill" ref="editorRef" @text-change="onEditorChange" v-model:content="formData.docContent" :options="quillOptions" contentType="html"/>
                            <!-- 使用自定义图片上传,如未使用下方的图片上传和视频上传，将直接转成base64形式存储 -->
                            <el-upload style="display: none;" ref="uploadRef" class="upload" accept="image/*" action="#" :on-change="domQuillImage" :auto-upload="false">
                                <template #trigger>
                                    <el-button id="dom-quill-img" type="primary" class="quill-img upload-btn">选择文件</el-button>
                                </template>
                            </el-upload>
                        </div>
                    </el-form-item>
                </el-form>
            </template>
            <template #footer>
                <div style="flex: auto">
                    <el-button type="primary" @click="onSubmit">发表</el-button>
                    <el-button @click="domShow = false">关闭</el-button>
                </div>
            </template>
        </el-drawer>
    </div>
</template>
<style>
#sys-dom{height: calc(100% - 200px);}
#sys-dom .el-col{text-align: left;}
#sys-dom .el-tag{margin-right: 5px;}
#sys-dom .el-form .el-form-item{margin-bottom: 5px;}
#sys-dom .dom-quill-div{height: 400px;}
#sys-dom .dom-quill-div #dom-quill{height: 330px;}
#dom-quill-img{display: none;}
.dom-tags-content{overflow: auto;white-space: nowrap;}
.ql-container{height: 200px;overflow: auto;width: 100%;}
.ql-toolbar.ql-snow {text-align: left;}
</style>
<script>
const DOC_TYPES = [{key:"01",value:"技术"},{key:"02",value:"生活"},{key:"03",value:"杂记"},{key:"04",value:"娱乐"},{key:"06",value:"工作"},{key:"05",value:"其他"}];
import CompBoard from "@/components/AppMainBoard.vue"

export default {
    name:"CompSysDom",
    data(){
        return{
            multipleSelection:[],docTypeVal:"",drawerShow:false,titleFilter:"",
            pageNum:1,pageSize:10,totalRows:0,docTypes:DOC_TYPES,
            list:[],tags:[],addFlag:false,formData:{},files:[],
            domShow:false,quillOptions:this.getQuillOptions(),quillImgIds:[]
        }
    },
    methods:{
        getQuillOptions(){
            return {
               placeholder: '请输入',
               theme: "snow",
               modules: {
                    toolbar:{
                         container: [
                            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                            ['blockquote', 'code-block'], // 引用  代码块
                            [{ header: 1 }, { header: 2 }], // 1、2 级标题
                            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                            [{ indent: '-1' }, { indent: '+1' }], // 缩进
                            // [{ direction: 'rtl' }], // 文本方向
                            [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
                            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                            [{ font: ['songti'] }], // 字体种类
                            [{ align: [false,'center','right','justify'] }], // 对齐方式
                            ['clean'], // 清除文本格式
                            ['image'] // 链接、图片，需要视频的可以加上video
                         ],
                         handlers: {   //此处是图片上传时候需要使用到的
                         'image': function (value) {
                                   if (value) {  // 点击图片
                                      document.querySelector('#dom-quill-img').click()
                                   }
                              }
                         }
                    },
                    //需添加对应模块
                    //imageDrop: true,   // 图片拖拽
                    imageResize: {     // 图片放大缩小
                          displayStyles: {
                                backgroundColor: "black",
                                border: "none",
                                color: "white"
                         },
                         modules: ["Resize", "DisplaySize", "Toolbar"]
                    }
               }
            }
        },
        docDateFmt(val){
            return this.$base.fmtDate(val,"yyyy/MM/dd");
        },
        docTypeFmt(docType){
            return DOC_TYPES.filter(p=>p.key==docType)[0].value;
        },
        docTagFmt(code){
            return this.tags.filter(p=>p.tagCode==code)[0].tagName;
        },
        showDrawer(row,flag){
            this.domShow = true;
            this.addFlag = flag;
            this.formData = {};
            if(this.addFlag){
                this.formData = {docTitle:"",docType:"01",docTagCodes:[],docContent:null,docState:1, docPosition:1};
            }else{
                this.formData = row;
            }
            setTimeout(()=>{
                this.loadQuillBarTip();
            },1500);
        },
        commentDom(row,idx){
            console.log(row,idx);
            this.drawerShow = true;
        },
        tagClick(tag){
            if(this.formData.docTagCodes.includes(tag.tagCode)){
                this.formData.docTagCodes = this.formData.docTagCodes.filter(p=>p!=tag.tagCode);
            }else{
                this.formData.docTagCodes.push(tag.tagCode);
            }
        },
        handleDelete(){
            let docIds = [];
            this.multipleSelection.forEach(p=>{
                docIds.push(p.docId);
            });
            this.$baseApi({data:{reqPath:'/doc/delDoc',docIds:docIds}})
            .then(res=>{
                res.code==1 ? this.$message({type:"success",message:"删除成功."}):this.$message({type:"error",message:res.message});
                this.loadData();
            }).catch(()=>{});
        },
        handleSizeChange(){
            console.log("handleSizeChange");
        },
        handleCurrentChange(val){
            this.pageNum = val;
            this.loadData();
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        handleReply(idx,row){
            this.drawerShow = true;
            Object.assign(this.formData,{email:row.email});
            console.log("handleReply",idx,row);
        },
        replySubmit(){
            console.log("replySubmit");
        },
        docTypeFilter(){
            this.pageNum = 1;
            this.loadData();
        },
        onSubmit(){
            this.$baseApi({data:Object.assign({reqPath:this.addFlag?"/doc/add":"/doc/updDoc"},this.formData)}).then(res=>{
                res.code==1 ? this.$message({type:"success",message:"操作成功."}):this.$message({type:"error",message:res.message});
                this.loadData();
            }).catch(()=>{});
        },
        loadData(){
            this.domShow = false;
            this.formData = {docTitle:"",docType:"01",docTagCodes:[],docContent:null,docState:1};
            this.$baseApi({data:{reqPath:"/doc/list",docType:this.docTypeVal,pageNum:this.pageNum,pageSize:this.pageSize}}).then(res=>{
                this.list = res.data.docs;
                this.totalRows = res.data.rows;
                this.tags = res.data.tags;
            }).catch(()=>{});
        },
        getImgPath(row){
            return `${this.profile}${row.itemSuffix}/${row.itemName}.${row.itemSuffix}`;
        },
        domQuillImage(file){
            if (file.size / 1024 / 1024 > 20) {
                this.$message({type:"error",message: `上传文件大小不能超过20M!`});
                this.$refs.uploadRef.clearFiles();
                return false;
            }
            this.files.push(file);
            if(this.files.length<1){
                this.$message({type:"error",message:"上传文件不能为空！"});
                return;
            }
            this.uploadImage();
        },
        uploadImage(){
            let formData = new FormData();
        //此处需要和后端接收的参数名相同
            formData.append("title", "DOC"+Date.now());
            formData.append("type", "01");
            formData.append("subType", "17");
            formData.append("openState", 0);
            // fileList 在data中定义的数组，把所有文件放到formData中
            this.files.forEach(f => {
                formData.append("files", f.raw);
            })
            this.files = [];
            this.$baseApi({
                url: '/file/upload',
                method: 'post',
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data:formData
            }).then(res=>{
                if(res.code!=1){
                    this.$message({type:"error",message:res.message});
                    return;
                }
                if(res.data && res.data.details){
                    res.data.details.forEach(p=>{
                        this.$message({type:1==p.code?"success":"error",message:p.message});
                        if(1==p.code && p.data && p.data.item){
                            let row = p.data.item;
                            let imgPath = this.getImgPath(row);
                            console.log("path",row,imgPath,this.quillImgIds);
                            let tempKey = row.uid+";"+row.itemName;
                            if(!this.quillImgIds.includes(tempKey)){
                                this.quillImgIds.push(tempKey);
                            }
                            this.insertImg(imgPath,"image");
                        }
                    })
                }
            }).catch(()=>{});
        },
        //插入图片
        insertImg(url,type){
            // 获取光标所在位置
            let quill = this.$refs.editorRef.getQuill();
            let length = quill.getSelection().index
            // 插入图片  
            quill.insertEmbed(length, type, url)  // imageUrl:图片地址
            // 调整光标到最后
            quill.setSelection(length + 1)
        },
        onEditorChange() {
            let quillDom = document.getElementById("dom-quill");
            let imgTags = quillDom.querySelectorAll("img");
            this.deleteQuillImgs(imgTags);
            
            // this.content = html
        },
        deleteQuillImgs(imgTags){
            if(imgTags.length != this.quillImgIds.length){
                for(let i=0;i<this.quillImgIds.length;i++){
                    let imgKey = this.quillImgIds[i];
                    let imgKeyId = imgKey.split(";")[0];
                    if(imgTags.length==0){
                        this.handleDeleteImg(imgKeyId,i);
                    }else{
                        let imgKeyName = imgKey.split(";")[1];
                        for(let j=0;j<imgTags.length;j++){
                            let imgTagSrc = imgTags[j].src;
                            if(imgTagSrc.indexOf(imgKeyName)<0){
                                this.handleDeleteImg(imgKeyId,i);
                            }
                        }
                    }
                }
            }
        },
        handleDeleteImg(uid,i){
            let uids = [];
            uids.push(uid);
            this.$baseApi({data:{uids,reqPath: '/file/delItems'}})
            .then(res=>{
                this.quillImgIds.splice(i, 1);
                this.files.splice(i, 1);
                res.code==1 ? this.$message({type:"success",message:"删除成功."}):this.$message({type:"error",message:res.message});
            }).catch(()=>{});
        },
        loadQuillBarTip(){
            for (let item of QUILL_BAR_TIP) {
                let tip = document.querySelector('.ql-toolbar ' + item.Choice)
                if (!tip) continue
                tip.setAttribute('title', item.title)
            }
        }
    },
    mounted(){
        this.loadData();
    },
    components:{CompBoard}
}
const QUILL_BAR_TIP = [
  { Choice: '.ql-insertMetric', title: '跳转配置' },
  { Choice: '.ql-bold', title: '加粗' },
  { Choice: '.ql-italic', title: '斜体' },
  { Choice: '.ql-underline', title: '下划线' },
  { Choice: '.ql-header', title: '段落格式' },
  { Choice: '.ql-strike', title: '删除线' },
  { Choice: '.ql-blockquote', title: '块引用' },
  { Choice: '.ql-code', title: '插入代码' },
  { Choice: '.ql-code-block', title: '插入代码段' },
  { Choice: '.ql-font', title: '字体' },
  { Choice: '.ql-size', title: '字体大小' },
  { Choice: '.ql-list[value="ordered"]', title: '编号列表' },
  { Choice: '.ql-list[value="bullet"]', title: '项目列表' },
  { Choice: '.ql-direction', title: '文本方向' },
  { Choice: '.ql-header[value="1"]', title: 'h1' },
  { Choice: '.ql-header[value="2"]', title: 'h2' },
  { Choice: '.ql-align', title: '对齐方式' },
  { Choice: '.ql-color', title: '字体颜色' },
  { Choice: '.ql-background', title: '背景颜色' },
  { Choice: '.ql-image', title: '图像' },
  { Choice: '.ql-video', title: '视频' },
  { Choice: '.ql-link', title: '添加链接' },
  { Choice: '.ql-formula', title: '插入公式' },
  { Choice: '.ql-clean', title: '清除字体格式' },
  { Choice: '.ql-script[value="sub"]', title: '下标' },
  { Choice: '.ql-script[value="super"]', title: '上标' },
  { Choice: '.ql-indent[value="-1"]', title: '向左缩进' },
  { Choice: '.ql-indent[value="+1"]', title: '向右缩进' },
  { Choice: '.ql-header .ql-picker-label', title: '标题大小' },
  { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一' },
  { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二' },
  { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三' },
  { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四' },
  { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五' },
  { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六' },
  { Choice: '.ql-header .ql-picker-item:last-child', title: '标准' },
  { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号' },
  { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号' },
  { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号' },
  { Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准' },
  { Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐' }
]

</script>
<template>
    <div id="app-main-board" v-loading="loading" element-loading-text="加载中...">
        <h3>共 <b>{{allRows}}</b> 条评论</h3>
        <el-card class="board-card box-card" shadow="hover" v-for="(comment,index) in comments" :key="index">
            <div class="board-top"><img src="../assets/images/nanjing.jpg"><b><span>{{ comment.userId?comment.userId:comment.userIp }}</span></b><small>{{ createTimeFmt(comment.createTime) }}</small></div>
            <div class="board-content">
                <p v-html="replyFmt(comment.commentContent)"></p>
                <div class="board-content-bars">
                    <div><el-tooltip class="box-item" effect="dark" content="评论数" placement="top-start" ><el-icon color="orange" size="20"><ChatDotRound /></el-icon></el-tooltip><i>{{ comment.subComments.length }}</i></div>
                    <span @click="replyClick(comment,'main',index,'')">回复</span>
                </div>
                <comp-input v-show="'main'+index == inputChoose" @compInputEmit="compInputEmit"></comp-input>
                <div class="board-reply" v-if="comment.subComments.length>0">
                    <div class="board-reply-content" v-for="(sub,subIdx) in comment.subComments" :key="subIdx">
                        <div class="board-top"><img src="../assets/images/nanjing.jpg"><span>{{ sub.userId?sub.userId:sub.userIp }}</span><small>to</small><span>{{ sub.toUserId?sub.toUserId:sub.userIp }}</span> <small>{{ createTimeFmt(sub.createTime) }}</small></div>
                        <p v-html="replyFmt(sub.commentContent)"></p>
                        <div class="board-bot"><span @click="replyClick(sub,'sub',index,subIdx)">回复</span></div>
                        <comp-input v-show="'sub'+index+subIdx == inputChoose" @compInputEmit="compInputEmit"></comp-input>
                        <el-divider />
                    </div>
                </div>
            </div>
        </el-card>
        <comp-footer v-if="!(dataProp && dataProp.uid)"></comp-footer>
    </div>
</template>
<style>
.board-card{width: 90%;margin: 10px auto;}
.board-card .el-divider{margin: 24px 0 12px 0;}
.board-card img{max-height: 30px;max-width: 30px;border-radius: 50%;margin: 0 5px;}
.board-card p{text-align: left;margin: 10px 0;}
.board-card small,.board-card b{padding: 5px;}
.board-card small{color: #8B8B8B;}
.board-card b,.board-card span{font-size: 12px;}
.board-card .board-top{width: 100%;height: 100%;display: flex;align-items: center;justify-items: flex-end;}
.board-card .board-content,.board-card .board-reply .board-reply-content{width: calc(100% - 10px);margin: 0 auto;font-size: 14px;}
.board-content .board-reply{margin: 10px auto;background: #F5FAFF;padding: 10px;border-radius: 10px;font-size: 12px;}
.board-content .board-reply p{font-size: 12px;}
.board-content .board-content-bars{height: 30px;display: flex;justify-content: flex-end;align-items: center;color: orange;}
.board-content .board-content-bars div{display: flex;align-items: center;}
.board-content .board-content-bars span{margin: 0 10px;cursor: pointer;}
.board-reply .board-reply-content{color: #5E5E5E;text-align: right;font-size: 14px;}
.board-reply .board-reply-content .board-bot{color: #4EA5FF;cursor: pointer;}
</style>
<script>
import CompInput from './comm/CompInput.vue';
import CompFooter from "@/components/comm/CompFooter.vue"
export default {
    name:"AppMainBoard",
    props:{
        dataProp:{type:Object,default:null},
        menuLimit:{type:Number, default:0}
    },
    data(){
        return{
            loading:true,allRows:0,subRows:0,inputChoose:"",replyRow:null,
            compInputData:null,
            comments:[],pageNum:1,pageSize:6
        }
    },
    methods:{
        createTimeFmt(val){
            return this.$base.fmtDate(val,"yyyy-MM-dd HH:mm");
        },
        replyClick(row,type,index,subIdx){
            this.inputChoose = type+index+subIdx;
            this.replyRow = row;
            console.log(row);
        },
        compInputEmit(obj){
            this.compInputData = obj;
            if(!(this.compInputData && this.compInputData.input.trim())){
                this.$message({type:"error",message:"内容不能为空."});
                return;
            }
            let cid = this.dataProp && this.dataProp.uid?this.dataProp.uid:"";
            let commentReq = {reqPath: '/comment/add',passFlag:this.menuLimit, contentId:cid,uid:this.replyRow.uid,commentContent:this.compInputData.input,commentType:"sys"};
            this.$baseApi({data:commentReq}).then(res=>{
                res.data && 1==res.code?
                    this.$message({type:"success",message:"留言成功."}):this.$message({type:"error",message:res.message});
                this.comments = [];
                this.loadData();
            }).catch(()=>{});
        },
        replyFmt(content){
            return this.$base.emojiToHtml(content);
        },
        lazyLoad(){
            let dom = this.$el;
            let bottomHeight = dom.scrollHeight-dom.scrollTop-dom.clientHeight;
            if(bottomHeight<=200 && this.comments.length+this.subRows<this.allRows){
                ++this.pageNum;
                this.loadData();
            }
        },
        loadData(){
            let cid = this.dataProp && this.dataProp.uid?this.dataProp.uid:"";
            this.$baseApi({data:{reqPath:"/comment/board",contentId:cid,pageNum:this.pageNum,pageSize:this.pageSize}}).then(res=>{
                if(1==res.code){
                    this.comments = this.comments.concat(res.data.comments);
                    this.allRows = res.data.rows;
                    this.subRows = res.data.subRows;
                }else{
                    this.$message({type:"error",message:"查询异常："+res.message});
                }
            }).catch(()=>{}).finally(()=>{this.loading=false});
        }
        
    },
    watch:{
        "dataProp.act"(obj){
            if(obj){
                this.comments = [];
                this.loadData();
            }
        }
    },
    components:{
        CompInput,CompFooter
    },
    mounted(){
        let that = this;
        this.loadData();
        that.$nextTick(function(){
            that.$el.addEventListener("scroll", that.lazyLoad);
        });
    }
}
</script>

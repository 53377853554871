<template>
    <div class="comp-music" v-show="musicShow">
        <div class="music-img"><img :src="playSong.imgSrc"/></div>
        <div class="music-main">
            <div class="main-top">
                <div class="top-title"><span>{{playSong.title}}</span></div>
                <div class="top-lrc"><span>{{playSong.lrc}}</span></div>
            </div>
            <div class="main-bottom">
                <div class="bottom-left">{{ playSong.timeLeft }}</div>
                <div class="bottom-line">
                    <el-slider v-model="playSong.timeProgress" :max="playSong.maxTimeProgress" @change="timeChange"/>
                </div>
                <div class="bottom-right">{{ playSong.timeRight }}</div>
                <div class="bottom-mode" @click="modeClick"><img v-if="playSong.mode" src="../../assets/images/music/order.png"><img v-else src="../../assets/images/music/random.png"></div>
                <div class="bottom-prev" @click="prevClick"><img src="../../assets/images/music/prev.png"></div>
                <div class="bottom-pause" @click="pauseClick"><img v-if="playSong.pause" src="../../assets/images/music/stop.png"><img v-else src="../../assets/images/music/play.png"></div>
                <div class="bottom-next" @click="nextClick"><img src="../../assets/images/music/next.png"></div>
                <div class="bottom-list" @click="listClick">
                    <img src="../../assets/images/music/list.png">
                    <div :class="['bottom-list-div',musicListShow?'list-div-show':'']">
                        <div v-for="(song,index) in songs" :key="index" @click.stop="chooseSong(index)"><span>{{ song.itemAliasName }}</span><small></small></div>
                    </div>
                </div>
                <div class="bottom-voice" @click="voiceClick">
                    <img v-if="playSong.voiceProgress>0" src="../../assets/images/music/voice.png"><img v-else src="../../assets/images/music/voice2.png">
                    <el-slider @click.stop="" v-show="voiceLineShow" v-model="playSong.voiceProgress" vertical @change="voiceChange"/>
                </div>
            </div>
        </div>
        <audio ref="myAudio" id="myAudio" :src="playSong.src" @timeupdate="currentTimeUpdate" autoplay preload="auto"></audio>
    </div>
</template>
<style>
#myAudio{position: absolute;left: -200%;}
.comp-music{width: 100%;height: 66px;opacity: 0.9;z-index: 9;background: #7e7e7e;position: fixed;bottom: 2px;left: 0;}
.comp-music .music-img{width: 66px;height: 66px;float: left;display: flex;justify-content: center;align-items: center;animation: rotating 9s linear infinite;}
.comp-music .music-img img{max-height: 90%;max-width: 90%;border-radius: 50%;}
.comp-music .music-main{width: calc(100% - 70px);height: 100%;float: right;}
.music-main .main-top,
.music-main .main-bottom{width: 98%;height: 50%;padding: 0 5px;align-items: center;display: flex;}
.music-main .main-top{width: calc(100% - 100px);}
.music-main .main-top>div,
.music-main .main-bottom>div{color: #FFF;}
.main-top .top-title{width: 26%;white-space: nowrap;overflow: hidden;text-align: left;}
.main-top .top-lrc{width: 100%;white-space: nowrap;overflow: hidden;}
.main-bottom .bottom-left,
.main-bottom .bottom-right{width: 40px;}
.bottom-mode,.bottom-prev,.bottom-pause,.bottom-next,.bottom-list,.bottom-voice{position: relative;width:20px;height: 20px;padding: 2px; margin-left: 2px;cursor: pointer;}
.main-bottom>div img{max-height: 100%;}
.bottom-list{position: relative;}
.bottom-list .bottom-list-div{
    position: absolute;
    display: none;
    width: 120px;
    height: 160px;
    overflow: auto;
    background: #7E7E7E;
    bottom: 62px;
    right: 0;
    border: 1px solid #FFF;
    text-align: left;
    border-radius: 5px;
    font-size: 12px;
}
.bottom-list .bottom-list-div>div{margin:3px 5px;white-space: nowrap;}
.bottom-list .bottom-list-div.list-div-show{display: block;}
.bottom-list .bottom-list-div>div:hover{font-size: 14px;color: #56ff56;}
.bottom-list .bottom-list-div>div small{margin-left: 5px;}
.bottom-right{margin-right: 30px;}
.bottom-line{width:60%;height: 2px;background: #333;border-radius: 5px;margin: 0 10px;}
.bottom-line .el-slider{width: 100%;height: 100%;background: #409EFF;}
.bottom-line .el-slider .el-slider__button{height: 12px;width: 12px;border-radius: 50%;background: #FFF;cursor: pointer;}
.bottom-voice .el-slider{position: absolute;bottom: 63px;left: 0;background: #7E7E7E;height: 160px;border-radius: 3px;}
.bottom-voice .el-slider .el-slider__runway{height: 80% !important;width: 2px;margin: 0 10px -5px 10px;}
.bottom-voice .el-slider .el-slider__runway .el-slider__bar{width:100% !important;}
.bottom-voice .el-slider .el-slider__button{position: absolute;bottom: 50%;height: 12px;width: 12px;left: 10px;border-radius: 50%;background: #FFF;}
</style>
<script>
export default {
    name:"CompMusic",
    props:{
        musicShow:{type:Boolean,default:false}
    },
    data(){
        return{
            musicListShow:false,voiceLineShow:false,
            songs:[],timeupdateFlag:true,pageNum:1,pageSize:20,
            playSong:{
                imgSrc:require("../../assets/images/nanjing.jpg"),
                title:"buer's music",
                lrc:"O ever youthful,O ever weeping.永远年轻，永远热泪盈眶。",
                src:"",
                star:"",
                timeLeft:"00:00",
                currentTime:0,
                duration:0,
                timeRight:"00:00",
                mode:true,//true顺序 false随机
                curIdx:0,
                pause:false,//false播放 true暂停
                timeProgress:0,
                maxTimeProgress:0,
                voiceProgress:30
            } 
        }
    },
    methods:{
        loadAudio(){
            this.$baseApi({data:{reqPath:"/file/items",type:"03",subType:"15",pageNum:this.pageNum,pageSize:this.pageSize}}).then(res=>{
                this.songs = res.data.items;
                this.loadPlaySong(0);
            }).catch(()=>{});
        },
        loadPlaySong(index){
            let song = this.songs[index];
            Object.assign(this.playSong,{curIdx:index,title:song.itemAliasName,src:this.getLocalPath(song),star:""});
            this.$refs.myAudio.volume = this.playSong.voiceProgress/100;
        },
        getLocalPath(row){
            return `${this.profile}${row.itemSuffix}/${row.itemName}.${row.itemSuffix}`;
        },
        listClick(){
            this.musicListShow = !this.musicListShow;
        },
        voiceClick(){
            this.voiceLineShow = !this.voiceLineShow;
        },
        voiceChange(val){
            this.playSong.voiceProgress = val;
            this.$refs.myAudio.volume = val/100;
        },
        chooseSong(idx){
            this.loadPlaySong(idx);
        },
        prevClick(){
            this.prevOrNextClick(-1);
        },
        nextClick(){
            this.prevOrNextClick(1);
        },
        prevOrNextClick(type){
            if(this.playSong.mode){
                this.playSong.curIdx = type>0? this.playSong.curIdx==(this.songs.length-1)?0:++this.playSong.curIdx:this.playSong.curIdx==0?this.songs.length-1:--this.playSong.curIdx;
            }else{
                let randomIdx = Number.parseInt(Math.random()*this.songs.length);
                this.playSong.curIdx = randomIdx;
            }
            this.loadPlaySong(this.playSong.curIdx);
        },
        modeClick(){
            this.playSong.mode = !this.playSong.mode;
        },
        pauseClick(){
            this.playSong.pause = !this.playSong.pause;
            this.playSong.pause ? this.$refs.myAudio.pause():this.$refs.myAudio.play();
        },
        fmtSecond(seconds){
            seconds = seconds||0;
            let val1 = Number.parseInt(seconds/60),val2 = Number.parseInt(seconds%60);
            return String(val1).padStart(2,'0')+":"+String(val2).padStart(2,'0');
        },
        timeChange(val){//和currentTimeUpdate存在冲突
            this.timeupdateFlag = false;
            this.playSong.timeProgress = val;
            this.$refs.myAudio.currentTime = val;
            this.timeupdateFlag = true;
        },
        currentTimeUpdate(){
            if(this.timeupdateFlag){
                Object.assign(this.playSong,{timeLeft:this.fmtSecond(this.$refs.myAudio.currentTime),
                    timeRight:this.fmtSecond(this.$refs.myAudio.duration),
                    timeProgress:this.$refs.myAudio.currentTime,
                    maxTimeProgress:this.$refs.myAudio.duration
                });
            }
            if(this.$refs.myAudio.currentTime>=this.$refs.myAudio.duration){
                this.nextClick();
            }
        }
    },
    mounted(){
        this.loadAudio();
    }
}
</script>
<template>
    <div id="comp-footer">
        <comp-public></comp-public>
        <p><small>声明：本站为非盈利性网站，网站部分内容来自于网络，如有侵权，请联系删除。<a href="mailto:bengcaca_xyz@163.com">email</a></small></p>
        <p><small>supported by buer.</small><a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备19054313号</a></p>
    </div>
</template>
<style>
#comp-footer{margin: 20px;}
</style>
<script>
import CompPublic from "@/components/comm/CompPublic.vue"
export default {
    name:"CompFooter",
    data(){
        return{
        }

    },
    methods:{
    },
    components:{
        CompPublic
    }
}
</script>
<template>
    <div id="app-main-about">
        <img src="../assets/images/nanjing.jpg">
        <div class="about-relation"><b class="about-qq"><img src="../assets/images/qq.png">1583033584</b><b class="about-wx"><img src="../assets/images/wechat.png">chc183</b></div>
        <comp-footer></comp-footer>

    </div>
</template>
<script>
import CompFooter from "@/components/comm/CompFooter.vue"
export default {
    name:"AppMainAbout",
    data(){
        return{
        }
    },
    methods:{
        
    },
    components:{CompFooter
    }
}
</script>
<style>
#app-main-about>img{max-width: 90%;max-height: 90%;margin: 20px auto;}
.about-relation,.about-relation b{height: 30px;margin: 20px;font-size: 20px;display: flex;justify-content: center;align-items: center;}
.about-relation b img{max-height: 100%;max-width: 100%;}
</style>
<template>
    <el-row>
        <el-col :span="24">
            <div class="comp-input-img">
                <img src="../../assets/images/comp/emoji.png" @click="emojiShow=!emojiShow">
                <!-- <img src="../../assets/images/comp/file.png"> -->
            </div>
        </el-col>
        <el-col :span="24">
            <div class="comp-input-content">
                <el-input v-model="inputVal" placeholder="请输入内容" type="textarea" :rows="inputPropRows()"></el-input>
                <div class="comp-emoji" v-if="emojiShow">
                    <img v-for="(emoji,index) in emojis" :key="index" :name="emoji.name" :src="emoji.path" :title="emoji.name" @click="emojiClick(index)"/>
                </div>
                <el-button type="primary" size="small" @click="okClick">{{ inputPropName() }}</el-button>
            </div>
        </el-col>
    </el-row>
</template>
<style>
.el-row{width: 100%;}
.comp-input-img{display: flex;align-items: center;margin: 5px;}
.comp-input-img img{max-height: 20px !important;max-width: 20px !important;cursor: pointer;}
.comp-input-content{position: relative;}
.comp-input-content button{position: absolute;bottom: 0;right: 0;margin: 5px;}
.comp-input-content .comp-emoji{width: 90%;height: 66px;overflow: auto;box-shadow: 0px 0px 5px 0px;padding: 10px;text-align: left;position: absolute;top: 0;background: #FFF;border-radius: 5px;z-index: 9;opacity: 0.9;}
.comp-input-content .comp-emoji img{max-width: 22px;max-height: 22px;margin:0 5px 0 0;cursor: pointer;}
</style>
<script>
import emojiList from '@/utils/emoji';
export default {
    name:"CompInput",
    props:{
        inputProp:{type:Object,default:null}
    },
    emits:['compInputEmit'],
    data(){
        return{
            inputVal:"",emojiShow:false,emojis:[]
        }
    },
    methods:{
        okClick(){
            this.$emit("compInputEmit",{input:this.inputVal});
        },
        emojiClick(index){
            this.inputVal += this.emojis[index].name;
            this.emojiShow = false;
        },
        inputPropRows(){
            return this.inputProp && this.inputProp.rows? this.inputProp.rows:1;
        },
        inputPropName(){
            return this.inputProp && this.inputProp.name? this.inputProp.name:"发送";
        }
    },
    mounted(){
        this.emojis = emojiList;
    },
    components:{}
}
</script>
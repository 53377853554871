<template>
    <el-row v-for="idx in Math.ceil(list.length/2)" :key="idx">
        <el-col :span="9" :offset="2" v-for="fun in listFmt(idx)" :key="fun.key">
            <div class="grid-content">{{ fun.name }}</div>
        </el-col>
    </el-row>
</template>
<script>
export default {
    name:"CompSysFactory",
    data(){
        return{
            list:[]
        }
    },
    methods:{
        funListShow(){
        },
        listFmt(idx){
            return this.list.slice((idx-1)*2,idx*2);
        }
    },
    mounted(){
        this.funListShow();
    },
    components:{
    }
}
</script>
<style>

</style>
import { createRouter,createWebHistory } from 'vue-router'
import { ElMessage } from 'element-plus'
import baseApi from '@/utils/baseApi'
import AppMain from '../components/AppMain.vue'
import AppMainGuide from '../components/AppMainGuide.vue'
import AppMainDoc from '../components/AppMainDoc.vue'
import AppLogin from '../components/AppLogin.vue'
import VueSys from '../components/sys/VueSys.vue'
import CompVueOfficeView from '../components/tool/VueOfficeFileView.vue'
import CompBuerPDFView from '../components/tool/BuerPDFView.vue'
import VueTest from '../components/VueTest.vue'

const routes = [
    {path:'/',name:"主页",component:AppMain},
    {path:'/login',name:"登录页",component:AppLogin},
    {path:'/sys',name:"管理页",component:VueSys},
    {path:'/guide',name:"导航页",component:AppMainGuide},
    {path:'/docs',name:"文档页",component:AppMainDoc},
    {path:'/view',name:"预览",component:CompVueOfficeView},
    {path:'/test',name:"测试页",component:VueTest},
    {path:'/pdf',name:"PDF阅读页",component:CompBuerPDFView}
]
const router = createRouter({
    history:createWebHistory(process.env.BASE_URL),
    routes
})
// 导航守卫
// 参数1 : to 目标路由对象
// 参数2 : from 来源路由对象
// 参数3 : next() 下一步
router.beforeEach((to, from, next) => {
    // 1. 判断是不是登录页面
    // 是登录页面
    let uid = sessionStorage.getItem("uid");
    if(to.path === '/sys'||to.path === '/test' || to.path === '/docs') {
      // 不是登录页面
      // 2. 判断 是否登录过
      if(to.path === '/docs' && to.query._uid && to.query._gid){
        baseApi({method:"post",data:{reqPath:"/login/info",uid:to.query._uid}}).then(()=>{
          next();
        }).catch(()=>{
          next('/login')
        });
      } else {
        if(!uid){
          ElMessage({type:"error",message:"请先登录!"});
          next('/login')
        }else{
          baseApi({method:"post",data:{reqPath:"/login/info", uid:uid, au:to.path != '/docs'?"sys":""}}).then(()=>{
            next();
          }).catch(()=>{
            next('/login')
          });
        }
      }
    }else {
      next()
    }
  })
export default router
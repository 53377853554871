<template>
    <div id="sys-guide">
        <el-row>
            <el-col :span="4"><el-button type="success" @click="handleAdd">新增</el-button></el-col>
            <el-col :span="20">
                <el-radio-group v-model="typeVal" style="margin-bottom: 20px" @change="typeChange">
                    <el-radio-button :label="1">外部</el-radio-button>
                    <el-radio-button :label="0">内部</el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-table :data="list" stripe>
            <el-table-column label="序号" width="60">
                <template #default="scope">
                    {{ scope.$index+1 }}
                </template>
            </el-table-column>
            <el-table-column label="导航名称" prop="guideName" width="180"/>
            <el-table-column label="图标" width="80">
                <template #default="scope">
                    <!-- <el-icon color="#409EFF" style="width: 1.6em;height: 1.6em;display: flex;align-items: center;"><component :is="scope.row.guideIcon" /></el-icon> -->
                    <img class="thumb-img" :src="onProfile(scope.row)">
                </template>
            </el-table-column>
            <el-table-column label="私有类型" width="100">
                <template #default="scope">
                    {{ 1==scope.row.privateType?'外部':"内部" }}
                </template>
            </el-table-column>
            <el-table-column label="导航类型" width="100">
                <template #default="scope">
                    {{ 0==scope.row.guideType?'默认':
                    1==scope.row.guideType?'文档文件':
                    2==scope.row.guideType?"文档链接":
                    3==scope.row.guideType?"操作与问题" :
                    "未知"}}
                </template>
            </el-table-column>
            <el-table-column label="状态" width="100">
                <template #default="scope">
                    <el-tag v-if="1==scope.row.guideState" type="success">启用</el-tag>
                    <el-tag v-else type="danger">停用</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="人员" prop="guideUser" width="100"/>
            <el-table-column label="链接/路径" prop="guideUrl">
                <template #default="scope">
                    <a :href="scope.row.guideUrl" :target="1==scope.row.privateType?'_blank':'_self'">{{ scope.row.guideUrl }}</a>
                </template>
            </el-table-column>
            <el-table-column label="排序" width="80">
                <template #default="scope">
                    <span><el-icon @click="onTop(scope.row)" color="orange"><Top/></el-icon><el-icon color="#B3B3B3"><Bottom/></el-icon></span>
                    </template>
                </el-table-column>
            <el-table-column fixed="right" width="140">
                <template #default="scope">
                    <el-button size="small" type="warning" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-drawer v-model="drawerShow" direction="rtl">
            <template #header><h4>菜单{{ addFlag?'新增':'编辑' }}</h4></template>
            <template #default>
                <el-form ref="form" :model="formData" label-width="80px">
                    <el-form-item v-if="!addFlag" label="编号">
                        <el-input readonly v-model="formData.uid"></el-input>
                    </el-form-item>
                    <el-form-item label="名称">
                        <el-input v-model="formData.guideName"></el-input>
                    </el-form-item>
                    <el-form-item label="图标/名称">
                        <el-input v-model="formData.guideIcon"></el-input>
                    </el-form-item>
                    <el-form-item label="链接/路径">
                        <el-input v-model="formData.guideUrl"></el-input>
                    </el-form-item>
                    <el-form-item label="描述">
                        <el-input v-model="formData.guideDesc"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="formData.guideState">
                            <el-option label="启用" :value="1"/>
                            <el-option label="停用" :value="0"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="私有类型">
                        <el-select v-model="formData.privateType">
                            <el-option label="外部" :value="1"/>
                            <el-option label="内部" :value="0"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="导航类型">
                        <el-select v-model="formData.guideType">
                            <el-option label="默认" :value="0"/>
                            <el-option label="文档文件" :value="1"/>
                            <el-option label="文档链接" :value="2"/>
                            <el-option label="操作与问题" :value="3"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="人员权限">
                        <el-select v-model="formData.guideUser">
                            <el-option value="ADMIN" label="ADMIN"/>
                            <el-option value="HOSP" label="HOSP"/>
                            <el-option v-for="(user, uidx) in guideUsers" :key="uidx" :value="user.username" :label="user.username"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="confirmClick">确定</el-button>
                    </el-form-item>
                </el-form>
            </template>
        </el-drawer>
    </div>
</template>
<style>
#sys-guide .el-col{text-align: left;}
.thumb-img{max-width: 80%;max-height: 80%;display: flex;border-radius: 5px;}
</style>
<script>
export default {
    name:"CompSysGuide",
    data(){
        return{
            drawerShow:false,addFlag:true,typeVal:1,
            formData:{},list:[] , guideUsers:[]
        }
    },
    methods:{
        onProfile(row){
            return `${this.profile}png/${row.guideIcon}.png`;
        },
        typeChange(){
            this.loadData();
        },
        onTop(row){
            console.log(row);
        },
        onBottom(row){
            console.log(row);
        },
        handleAdd(){
            this.drawerShow = true;
            this.addFlag = true;
            this.formData = {guideState:1, privateType:1 , guideType:1};
        },
        handleEdit(row){
            this.drawerShow = true;
            this.addFlag = false;
            Object.assign(this.formData,row);
        },
        handleDelete(row){
            this.$baseApi({
                data:Object.assign({reqPath: '/sys/delGui'},row)
            }).then(()=>{
                this.drawerShow = false;
                this.loadData();
            })
        },
        confirmClick(){
            this.$baseApi({
                data:Object.assign({reqPath:this.addFlag?'/sys/addGui':'/sys/updGui'},this.formData)
            }).then(()=>{
                this.drawerShow = false;
                this.loadData();
            })
        },
        async loadData(){
            let res = await this.$baseApi({data:{reqPath:"/login/users"}});
            this.guideUsers = res.data.users;
            this.$baseApi({data:{reqPath:"/sys/guides",privateType:this.typeVal}}).then(res=>{
                this.list = res.data.guides;
            });
        }
    },
    mounted(){
        this.loadData();
    }
}
</script>